import Container from "../../components/Container";
import React, {useCallback, useEffect, useState} from "react";
import {FlatList, Text} from 'react-native';
import {useSafeAreaInsets} from 'react-native-safe-area-context';
import SessionListItem from "./components/SessionListItem";
import {ChatSession} from "../../models";
import HeaderButton from "../../components/HeaderButton";
import {useTheme} from "@react-navigation/native";
import {Alert} from "../../base";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import SelectionBottomBar, {BAR_HEIGHT as SELECTION_BAR_HEIGHT} from "./components/SelectionBottomBar";

type Props = NativeStackScreenProps<RootStackParamList, "History">;
const HistoryScreen = ({navigation, route}: Props) => {
    const currentSessionId = route?.params?.sessionId;
    const keyExtractor = (item: { id: string }, index: number) => item.id;
    const [data, setData] = useState([]);
    const [selecting, setSelecting] = useState(false);
    const [selectedIds, setSelectedIds] = useState<string[]>([]);
    const {colors, dark} = useTheme();
    const insets = useSafeAreaInsets();

    useEffect(() => {
        navigation.setOptions({
            headerRight: ({tintColor}) => {
                return <HeaderButton onPress={() => {
                    setSelecting(!selecting);
                }}>
                    <Text style={{color: tintColor, fontSize: 17}}>{selecting ? '取消' : '选择'}</Text>
                </HeaderButton>
            }
        })
    }, [navigation, selecting])

    useEffect(() => {
        load();
    }, []);

    const load = useCallback(() => {
        ChatSession.objects.allAsync().then(sessions => {
            setData(sessions.reverse());
        })
    }, []);

    const renderItem = ({item}: { item: any }) => {
        const {title, id} = item;
        return (
            <SessionListItem
                selectMode={selecting}
                title={title}
                onPress={() => navigation.navigate('Chat', {sessionId: id})}
                onSelectionChange={value => {
                    const set = new Set<string>(selectedIds);
                    if (value) {
                        set.add(id);
                    } else {
                        set.delete(id);
                    }
                    setSelectedIds([...set])
                }}
            />
        )
    };

    return (
        <Container>
            <FlatList
                data={data}
                renderItem={renderItem}
                keyExtractor={keyExtractor}
                contentContainerStyle={{
                    paddingBottom: insets.bottom + (selecting ? SELECTION_BAR_HEIGHT : 0),
                }}
            />
            {selecting &&
                <SelectionBottomBar
                    selectCount={selectedIds.length}
                    onPressDelete={() => {
                        Alert.alert('删除会话', `确定删除选中的${selectedIds.length}个会话？`, [
                            {text: '取消'},
                            {
                                text: '确定',
                                onPress: async () => {
                                    for (let id of selectedIds) {
                                        await ChatSession.objects.deleteAsync({id});
                                    }
                                    setSelecting(false);
                                    load();
                                }
                            }
                        ])
                    }}
                />
            }
        </Container>
    )
}

export default HistoryScreen;
