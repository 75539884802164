import {DarkTheme as DefaultDarkTheme} from '@react-navigation/native';

const DarkTheme = {
    dark: true,
    colors: {
        ...DefaultDarkTheme.colors,
        background: '#343540',
        card: '#3E3F4A',
        border: '#2A2B31',
        headerTint: '#FFFFFF',
        headerBackground: '#343540',
    },
};


export default DarkTheme;