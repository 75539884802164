import {Animated, Easing, Platform, Text} from "react-native";
import React, {useEffect, useState} from "react";

type Props = {
    title: string,
}
const defaultColors = ['#F3B88B', '#FCE68D', '#96BDE9', '#B69AD1', '#EFB5D4'];
const TitleCard: React.FC<Props> = ({title}) => {
    const [scaleValue, setScaleValue] = useState(new Animated.Value(0))
    const [colorValue, setColorValue] = useState(new Animated.Value(0))
    const [colors, setColors] = useState(defaultColors)
    const textComponents = [];

    useEffect(() => {
        const interval = setInterval(() => {
            const colors = [...defaultColors]
            colors.sort(() => Math.random() - 0.5);
            setColors((colors));
        }, 2000)
        return () => {
            clearInterval(interval);
        }
    }, [])

    useEffect(() => {
        Animated.timing(scaleValue, {
            toValue: 1,
            duration: 500,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== 'web',
        }).start();
        const ani1 = Animated.timing(colorValue, {
            toValue: 1,
            duration: 3000,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== 'web',
        });
        const ani2 = Animated.timing(colorValue, {
            toValue: 0,
            duration: 3000,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== 'web',
        });
        Animated.loop(Animated.sequence([ani1, ani2])).start();
    }, [])

    for (let i = 0; i < title.length; i++) {
        const colorIndex = i % colors.length;
        const color = colors[colorIndex];
        textComponents.push(
            <Text key={i} style={{color}}>{title[i]}</Text>
        );
    }
    return (
        <Animated.View style={{
            backgroundColor: Platform.OS==='web'?colorValue.interpolate({
                inputRange: [0, 1],
                outputRange: ['#D2EAEB', '#FFFFFF'],
            }):'#FFFFFF',
            borderRadius: 10,
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 12,
            paddingLeft: 12,
            transform: [{scale: scaleValue}],
        }}>
            <Text style={{
                fontSize: 45,
                fontWeight: 'bold',
                fontFamily: 'WXkatonglemiao',
                lineHeight: 50,
            }}>{textComponents}</Text>
        </Animated.View>
    )

}

export default TitleCard;