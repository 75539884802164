import {Plugin} from "./types";
import * as Location from 'expo-location';

const LocationPlugin: Plugin = {
    id: 'location',
    name: '定位查询',
    description: '该插件允许AI助理查询用户地理位置。',
    functions: [
        {
            func: async () => {
                let {status} = await Location.requestForegroundPermissionsAsync();
                if (status !== 'granted') {
                    throw Error('访问位置的权限被拒绝。');
                }
                return await Location.getCurrentPositionAsync({});
            },
            meta: {
                name: "get_current_position",
                description: "Requests for one-time delivery of the user's current location",
                parameters: {
                    type: "object",
                    properties: {}
                }
            }
        },
    ]
}

export default LocationPlugin;