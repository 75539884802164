import React, {useEffect, useRef, useState} from "react";
import {ImageSourcePropType, Animated, Easing, Platform, TouchableOpacity} from "react-native";
import {GestureResponderEvent} from "react-native/Libraries/Types/CoreEventTypes";
import {StyleProp} from "react-native/Libraries/StyleSheet/StyleSheet";
import {ViewStyle} from "react-native/Libraries/StyleSheet/StyleSheetTypes";

interface Props {
    source: ImageSourcePropType;
    size?: number;
    style?: StyleProp<ViewStyle> | undefined;
    onPress?: ((event: GestureResponderEvent) => void) | undefined,
}

const RotatingImage: React.FC<Props> = ({size, source, style, onPress}) => {
    const [spinValue, setSpinValue] = useState(new Animated.Value(0))
    const interval = useRef<number>(100);

    useEffect(() => {
        const aniMotion = Animated.timing(spinValue, {
            toValue: 1,
            duration: 2000,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== 'web',
        });
        // 循环执行这个动画
        Animated.loop(aniMotion).start()
    }, [])

    const spin = spinValue.interpolate({
        inputRange: [0, 1],//输入值
        outputRange: ['0deg', '360deg'] //输出值
    });

    return (
        <TouchableOpacity onPress={onPress} style={style}>
            <Animated.Image
                source={source}
                style={{width: size, height: size, transform: [{rotate: spin}]}}/>
        </TouchableOpacity>
    );
}


export default RotatingImage;