import {TouchableOpacity} from "react-native";
import {GestureResponderEvent} from "react-native/Libraries/Types/CoreEventTypes";

type Props = {
    children: React.ReactElement,
    onPress?: ((event: GestureResponderEvent) => void) | undefined;
}

const QuickSettingsButton: React.FC<Props> = (props) => {
    const {children, onPress} = props;
    return (
        <TouchableOpacity
            onPress={onPress}
            style={{
                width: 35,
                height: 35,
                justifyContent: 'center',
                alignItems: "center",
            }}>
            {children}
        </TouchableOpacity>
    )
}

export default QuickSettingsButton;