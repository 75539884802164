import React from 'react';
import {View, StyleSheet} from 'react-native';
import AlertCard from "./AlertCard";
import {Button} from "./types";

type Props = {};
type State = {
    visible: boolean,
    title: string,
    message: string,
    buttons?: Button[],
};
export default class Alert extends React.Component<Props, State> {

    state = {
        visible: false,
        title: '',
        message: '',
        buttons: [],
    }
    show = (title: string, message: string, buttons?: Button[]) => {
        this.setState({visible: true, title, message, buttons});
    };

    hide = () => {
        this.setState({visible: false, title: '', message: '', buttons: []});
    };

    render() {
        const {visible, title, message, buttons} = this.state;
        if (!visible) {
            return null;
        }
        return (
            <View style={{
                ...StyleSheet.absoluteFillObject,
                backgroundColor: 'rgba(0,0,0,0.3)',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <AlertCard
                    closeAlert={() => this.hide()}
                    title={title}
                    message={message}
                    buttons={buttons}/>
            </View>
        )
    }
}