import React, {useRef} from 'react';
import {GestureResponderEvent, TouchableWithoutFeedback} from 'react-native';

interface Props {
    clicksNeeded: number;
    timeInterval?: number;
    onPress: (event?: GestureResponderEvent | undefined) => void;
    children: React.ReactNode;
}

const MultiClickTouchable: React.FC<Props> = ({clicksNeeded, timeInterval = 500, onPress, children}) => {
    const lastClickTime = useRef(0)
    const clickCount = useRef(0);

    const handlePress = (event: GestureResponderEvent) => {
        const now = new Date().getTime();
        if (now - lastClickTime.current > timeInterval) {
            clickCount.current = 1;
        } else {
            clickCount.current = clickCount.current + 1;
        }
        lastClickTime.current = now;
        if (clickCount.current >= clicksNeeded) {
            onPress(event);
            clickCount.current = 0;
            lastClickTime.current = 0;
        }
    };

    return <TouchableWithoutFeedback onPress={handlePress}>{children}</TouchableWithoutFeedback>;
};

MultiClickTouchable.defaultProps = {
    timeInterval: 500,
}

export default MultiClickTouchable;