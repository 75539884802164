import React from "react";
import ActionSheet from "./ActionSheet";
import {actionSheetRef} from "../refs/ActionSheet";
import {SafeAreaProvider} from 'react-native-safe-area-context';
import Toast from "./Toast";
import {toastRef} from "../refs/Toast";
import {alertRef} from "../refs/Alert";
import Alert from "./Alert";

type Props = {
    actionSheetEnabled?: boolean,
    toastEnabled?: boolean,
    alertEnabled?: boolean,
    colorScheme?: 'light' | 'dark',
    children?: React.ReactNode,
}

const Root: React.FC<Props> = (props) => {
    const {actionSheetEnabled, toastEnabled, alertEnabled, colorScheme, children} = props;
    return (
        <SafeAreaProvider>
            {children}
            {actionSheetEnabled && <ActionSheet ref={actionSheetRef} colorScheme={colorScheme}/>}
            {toastEnabled && <Toast ref={toastRef}/>}
            {alertEnabled && <Alert ref={alertRef}/>}
        </SafeAreaProvider>
    )
};

Root.defaultProps = {
    actionSheetEnabled: true,
    toastEnabled: true,
    alertEnabled: true,
    colorScheme: 'light',
};

export default Root;