import {StyleSheet, View} from "react-native";
import Text from "../Text";
import React from "react";

type Props = {
    reason?: string,
}

const FailedReason:React.FC<Props> = (props) => {
    const {reason} = props;
    return (
        <View style={{
            borderWidth: StyleSheet.hairlineWidth,
            borderColor: '#EF4154',
            borderRadius: 5,
            backgroundColor: 'rgba(239, 65, 84,0.4)',
            paddingTop: 5,
            paddingBottom: 5,
            paddingRight: 12,
            paddingLeft: 12,
            alignSelf: 'baseline'
        }}>
            <Text style={{
                fontSize: 17,
            }}>{reason}</Text>
        </View>
    )
}

export default FailedReason;