import {Plugin} from "./types";
import * as Device from 'expo-device';

const functionCall: Plugin = {
    id: 'device-info',
    name: '设备信息',
    description: '该插件允许AI助理查询当前设备信息。',
    functions: [
        {
            func: () => {
                return Device;
            },
            meta: {
                name: "get_device_info",
                description: "Get system information about the physical device, such as its manufacturer and model",
                parameters: {
                    type: "object",
                    properties: {}
                }
            }
        },
    ]
}

export default functionCall;