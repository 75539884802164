import React from "react";
import {TouchableHighlight, StyleSheet, Text, View} from "react-native";
import * as PropTypes from "prop-types";
import {useSafeAreaInsets} from "react-native-safe-area-context";

const ActionSheetItem = (props) => {
    const insets = useSafeAreaInsets();
    const {text, style, height, textStyle, onPress, onLongPress, last, colorScheme} = props;
    const dark = colorScheme === 'dark';
    const borderColor = dark ? '#333333' : '#E4E4E4';
    const backgroundColor = dark ? '#2C2C2C' : '#FFFFFF';
    const underlayColor = dark ? '#FFFFFF' : '#252525';
    const textColor = dark ? '#FFFFFF' : '#000000';
    return <TouchableHighlight onPress={onPress} onLongPress={onLongPress} underlayColor={underlayColor}
                               activeOpacity={0.94} style={style}>
        <View style={{
            borderBottomWidth: last ? 0 : StyleSheet.hairlineWidth,
            borderColor,
        }}>
            <View style={[styles.container, {
                height: height,
                backgroundColor: backgroundColor,
            }]}>
                <Text style={[styles.text, {color: textColor}, textStyle]}>{text}</Text>
            </View>
            {insets.bottom > 0 && last ? <View style={{
                backgroundColor: backgroundColor,
                height: insets.bottom,
            }}/> : null}
        </View>
    </TouchableHighlight>
};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: 17
    },
});

const ITEM_HEIGHT = 56;

ActionSheetItem.propTypes = {
    onPress: PropTypes.func,
    onLongPress: PropTypes.func,
    text: PropTypes.string,
    height: PropTypes.number,
    style: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.number,
    ]),
    textStyle: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.array,
        PropTypes.number,
    ]),
    last: PropTypes.bool,
    colorScheme: PropTypes.oneOf(['light', 'dark']),
};

ActionSheetItem.defaultProps = {
    height: ITEM_HEIGHT,
    last: false,
};

export {ITEM_HEIGHT};

export default ActionSheetItem;