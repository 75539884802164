import React, {useRef} from 'react';
import {FlatList, StyleSheet, View, Platform} from "react-native";
import UserMessage from "./UserMessage";
import BotMessage from "./BotMessage";
import {Message} from "./types";
import SystemMessage from "./SystemMessage";

type Props = {
    messages: Message[],
}

const Chat: React.FC<Props> = ({messages}) => {
    const flatListRef = useRef<FlatList>(null);
    const keyExtractor = (item: Message, index: number) => `${index}`;

    const renderItem = ({item}: { item: Message }) => {
        const {user} = item;
        if (user === 'bot') {
            return <BotMessage {...item} />;
        } else if(user==='system'){
            return <SystemMessage {...item} />;
        } else {
            return <UserMessage {...item} />;
        }
    };

    const handleContentSizeChange = () => {
        if (Platform.OS === 'web') {
            window.scrollTo(0, document.body.scrollHeight);
            flatListRef.current?.scrollToEnd({animated: true});
        } else {
            flatListRef.current?.scrollToEnd({animated: true});
        }
    };

    return (
        <View style={{flex: 1}}>
            <FlatList
                bounces={false}
                ref={flatListRef}
                data={messages}
                keyExtractor={keyExtractor}
                renderItem={renderItem}
                contentContainerStyle={styles.contentContainer}
                onContentSizeChange={handleContentSizeChange}
            />
        </View>
    )
};

const styles = StyleSheet.create({
    contentContainer: {
        flexGrow: 1,
    },
});

export default Chat;