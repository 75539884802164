import Container from "../components/Container";
import Text from "../components/Text"
import {ScrollView, View} from "react-native";
import AppConstants from "../constants/AppConstants";
import QRCode from "../components/QRCode";
import Env from "../constants/Env";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "WeChatOfficialAccount">;
const WeChatOfficialAccountScreen = (props: Props) => {
    const {route} = props;
    const message = route.params?.message;
    return (
        <Container>
            <ScrollView contentContainerStyle={{
                padding: 12,
                paddingTop: 20,
            }}>
                <View style={{
                    alignItems: 'center',
                    paddingTop: 30,
                }}>
                    {message &&
                        <Text style={{
                            marginTop: 15,
                            fontSize: 17,
                            marginBottom: 10,
                        }}>{message}</Text>}
                    <View>
                        <QRCode
                            size={200}
                            value={AppConstants.WECHAT_OFFICIAL_ACCOUNT_QR}
                        />
                    </View>
                    <Text style={{
                        marginTop: 15,
                        fontSize: 17,
                    }}>{TITLE}</Text>
                </View>
            </ScrollView>
        </Container>
    )
};

const TITLE = Env.WECHAT_BROWSER ? '长按二维码前往公众号' : '微信扫二维码前往公众号';

export default WeChatOfficialAccountScreen;