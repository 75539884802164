import {SearchRequest, SearchResult, SearchEngine, RequestOptions} from "./types";
import {fetchWithTimeout as fetch} from '../openai';

type DuckDuckGoRemoteInit = {
    baseUrl?: string,
    auth?: string,
}

export default class DuckDuckGoRemote implements SearchEngine {
    baseUrl: string;
    auth?: string;

    constructor(init: DuckDuckGoRemoteInit = {}) {
        const {baseUrl = 'https://wx.feng.to/api', auth} = init;
        this.baseUrl = baseUrl;
        this.auth = auth;
    }

    async search(params: SearchRequest, options?: RequestOptions): Promise<SearchResult[]> {
        const {query, timeRange, region, numResults} = params;
        const data = {
            query: query.slice(0, 495), // DDG limit
            time_range: timeRange,
            region: region,
            num_results: numResults,
        }

        const headers: HeadersInit = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        }
        if (this.auth) {
            headers['X-OPENAI-API-KEY'] = this.auth
        }

        const response = await fetch(`${this.baseUrl}/v1/search/`, {
            method: 'POST',
            headers,
            body: JSON.stringify(data),
            ...options,
        })

        if (!response.ok) {
            throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`)
        }

        const {results} = await response.json();

        return results;
    }
}