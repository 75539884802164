import {SearchRequest, SearchResponse, SearchResult, SearchEngine} from "./types";

const BASE_URL = 'https://lite.duckduckgo.com'


export async function getHtml({query, timeRange, region}: SearchRequest): Promise<SearchResponse> {

    const formData = new FormData();
    formData.append('q', query.slice(0, 495));
    formData.append('df', timeRange ? timeRange : '');
    formData.append('kl', region ? region : '');

    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'text/html,application/xhtml+xml,application/xmlq=0.9,image/avif,image/webp,image/apng,*/*q=0.8,application/signed-exchangev=b3q=0.7',
        // AcceptEncoding: 'gzip, deflate, br',
    }

    const response = await fetch(`${BASE_URL}/lite/`, {
        method: 'POST',
        headers,
        body: formData,
    })

    if (!response.ok) {
        throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`)
    }

    return {status: response.status, html: await response.text(), url: response.url}
}


export default class DuckDuckGo implements SearchEngine {
    async search(params: SearchRequest): Promise<SearchResult[]> {
        const {html} = await getHtml(params);
        throw new Error('Not implemented')
    }
}