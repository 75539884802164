import {DuckDuckGoRemote} from "../web-access";
import {Plugin} from "./types";

type Params = {
    query: string
}

const WebSearch: Plugin = {
    id: 'web-search',
    name: '网页搜索',
    description: '"网页搜索"插件允许AI助理使用搜索引擎查询指定的信息。',
    functions: [
        {
            func: async ({query}: Params) => {
                const engine = new DuckDuckGoRemote();
                return engine.search({
                    query,
                    numResults: 3,
                });
            },
            meta: {
                name: 'web_search',
                description: 'Search the specified content through the search engine',
                parameters: {
                    type: 'object',
                    properties: {
                        query: {
                            type: 'string',
                            description: 'Content that needs to be searched by search engines'
                        }
                    },
                    required: ['query']
                }
            }
        },
    ]

};
export default WebSearch;