import React from 'react';
import {View} from 'react-native';
import PropTypes from "prop-types";

const Divider = ({lineWidth, vertical, color, style, length}) => {
    const lineStyle = {};
    if (vertical) {
        lineStyle['width'] = lineWidth;
        lineStyle['height'] = length;
    } else {
        lineStyle['height'] = lineWidth;
        lineStyle['width'] = length;
    }
    return <View style={{
        ...lineStyle,
        backgroundColor: color,
        ...style,
    }}/>
};


Divider.propTypes = {
    vertical: PropTypes.bool,
    lineWidth: PropTypes.number,
    length: PropTypes.number,
};

Divider.defaultProps = {
    lineWidth: 1,
    vertical: false,
    color: 'grey',
    style: PropTypes.any,
};

export default Divider;
