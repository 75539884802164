import React, {createRef} from 'react';
import {
    StyleSheet,
    TextInput,
    View,
    KeyboardAvoidingView,
    Platform,
    Pressable,
    Keyboard,
    TextStyle
} from "react-native";
import {SafeAreaInsetsContext} from "react-native-safe-area-context";
import {Theme, useTheme} from '@react-navigation/native';
import QuickSettingsBar from "./QuickSettingsBar";
import DoneButton from "../DoneButton";
import {MaterialCommunityIcons} from "@expo/vector-icons";
import {NativeSyntheticEvent} from "react-native/Libraries/Types/CoreEventTypes";
import {TextInputKeyPressEventData} from "react-native/Libraries/Components/TextInput/TextInput";
import {Message} from "./types";

const nanoid = Platform.OS === 'web' ? require('nanoid').nanoid : require('nanoid/non-secure').nanoid;

type Props = {
    theme: Theme
    onSend?: (message: Message) => void,
    onPressStop?: () => void,
    onPressClean?: () => void,
    onPressSystemMessage?: () => void,
    generating?: boolean,
}

type State = {
    text: string
}

export default class InputToolBar extends React.Component<Props, State> {

    static defaultProps = {
        generating: false,
    }
    textInputRef: React.RefObject<TextInput> = createRef();

    state = {
        text: '',
    }

    setInputText = (text: string) => {
        this.setState({text});
    }

    private onKeyPress = (event: NativeSyntheticEvent<TextInputKeyPressEventData>) => {
        const keyPressEvent = event as { shiftKey?: any, key?: string } //解决Web中TypeScript类型问题
        const {onSend} = this.props;
        const {text} = this.state;
        if (keyPressEvent.shiftKey && keyPressEvent.key === 'Enter') {
        } else if (keyPressEvent.key === 'Enter') {
            onSend && onSend({text, id: nanoid()});
            this.setInputText('');
            Keyboard.dismiss();
        }
    }

    render() {
        const {theme, onSend, generating, onPressStop, onPressClean, onPressSystemMessage} = this.props;
        const {text} = this.state;
        const {colors, dark} = theme;
        return (
            <KeyboardAvoidingView
                behavior={Platform.OS === 'ios' ? 'padding' : 'height'} keyboardVerticalOffset={55}>
                <SafeAreaInsetsContext.Consumer>
                    {(insets) => {
                        return (

                            <View style={{
                                paddingBottom: insets?.bottom,
                            }}>
                                <InputToolBarContainer>
                                    <QuickSettingsBar
                                        onPressClean={onPressClean}
                                        onPressSystemMessage={onPressSystemMessage}/>
                                    <View style={{
                                        paddingRight: 12,
                                        paddingLeft: 12,
                                    }}>
                                        <TextInputContainer>
                                            <TextInput
                                                ref={this.textInputRef}
                                                value={text}
                                                onChangeText={text => this.setState({text})}
                                                multiline
                                                autoFocus={Platform.OS === 'web'}
                                                placeholder={'输入消息'}
                                                placeholderTextColor={'#CCCCCC'}
                                                onKeyPress={Platform.OS === 'web' ? this.onKeyPress : undefined}
                                                style={[
                                                    styles.textInput,
                                                    {
                                                        borderColor: dark ? '#303138' : '#E5E5E5',
                                                        backgroundColor: dark ? '#40414E' : '#FFFFFF',
                                                        color: colors.text,
                                                    },
                                                    //消除web中获得焦点显示的边框
                                                    Platform.OS === 'web' ? {outline: 'none'} as TextStyle : null]
                                                }
                                            />
                                            {generating &&
                                                <Pressable
                                                    onPress={onPressStop}
                                                    style={{
                                                        height: 30,
                                                        width: 30,
                                                        marginLeft: 10,
                                                        alignSelf: 'center',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                    }}>
                                                    <MaterialCommunityIcons name="stop-circle-outline" size={30}
                                                                            color={colors.text}/>
                                                </Pressable>
                                            }
                                            {!generating && text.length > 0 &&
                                                <DoneButton
                                                    title={'发送'}
                                                    onPress={() => {
                                                        onSend && onSend({text, id: nanoid()});
                                                        this.setState({text: ''})
                                                    }}
                                                    style={{
                                                        marginLeft: 10,
                                                        marginBottom: 5,
                                                    }}/>}
                                        </TextInputContainer>
                                    </View>
                                </InputToolBarContainer>
                            </View>
                        )
                    }}
                </SafeAreaInsetsContext.Consumer>
            </KeyboardAvoidingView>
        )
    }

}

const styles = StyleSheet.create({
    textInput: {
        paddingRight: 10,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10,
        borderRadius: 5,
        borderWidth: StyleSheet.hairlineWidth,
        maxHeight: 200,
        fontSize: 17,
        flex: 1,
    }
})

type InputToolBarContainerProps = {
    children: React.ReactNode,
};
const InputToolBarContainer = ({
                                   children
                               }: InputToolBarContainerProps) => {
    const {colors, dark} = useTheme();
    return (
        <View style={{
            flexDirection: 'column',
            borderTopColor: dark ? '#44464A' : '#D8D8D8',
            backgroundColor: colors.background,
            borderTopWidth: StyleSheet.hairlineWidth,
            width: '100%',
            paddingBottom: 10,
        }}>
            {children}
        </View>
    )
}

type TextInputContainerProps = {
    children: React.ReactNode,
}
const TextInputContainer = ({children}: TextInputContainerProps) => {
    const {dark} = useTheme();
    return (
        <View style={{
            flexDirection: 'row',
            alignItems: 'flex-end',
        }}>
            {children}
        </View>
    )
}