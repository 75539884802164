import {Platform, StyleSheet, View} from "react-native";
import React from "react";
import {StatusBar} from "expo-status-bar";
import PropTypes from "prop-types";

const Container = (props) => {
    const {children, style, padding} = props;
    return (
        <View style={[styles.container, padding ? {
            paddingRight: 8,
            paddingLeft: 8,
            paddingTop: 8,
        } : {}, style]}>
            {children}
            <StatusBar style="light"/>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
});

Container.propTypes = {
    padding: PropTypes.bool,
    children: PropTypes.any,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]),
}
export default Container;