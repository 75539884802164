import {createParser} from 'eventsource-parser';
import {isEmpty} from 'lodash-es';
import {streamAsyncIterable} from './stream-async-iterable';

export async function fetchSSE(resource: string, options: RequestInit & { onMessage: (message: string) => void }) {
    const {onMessage, ...fetchOptions} = options;
    const resp = await fetch(resource, fetchOptions);
    if (!resp.ok) {
        const error = await resp.json().catch(() => ({}));
        throw new Error(!isEmpty(error) ? JSON.stringify(error) : `${resp.status} ${resp.statusText}`);
    }
    const body = resp.body;
    if (body) {
        return handleSseStream(body, onMessage);
    }
}

export const handleSseStream = async (stream: ReadableStream | null, onMessage: (message: string) => void) => {
    if (stream === null) {
        return;
    }
    const parser = createParser((event) => {
        if (event.type === 'event') {
            onMessage(event.data);
        }
    });
    for await (const chunk of streamAsyncIterable(stream)) {
        const str = new TextDecoder().decode(chunk);
        parser.feed(str);
    }
}