import * as React from 'react';
import Toast from "../components/Toast";
import {ShowToastParams} from "../components/Toast/types";

export const toastRef = React.createRef<Toast>();

export function show({text, type = 'default', position = 'bottom', duration = 1000}: ShowToastParams) {
    toastRef.current?.show({text, type, position, duration});
}

export default {
    show,
}