import {StyleSheet} from "react-native";
import PropTypes from "prop-types";
import BaseButton from "../base/components/BaseButton";

const DoneButton = ({onPress, disabled, title, style}) => {
    return (
        <BaseButton
            disabled={disabled}
            onPress={onPress}
            style={[styles.container, style]}
            title={title}
            titleStyle={styles.text}/>
    );
};

const styles = StyleSheet.create({
    container: {
        height: 30,
        paddingRight: 8,
        paddingLeft: 8,
        borderRadius: 3,
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: 8,
    },
    text: {
        fontSize: 16,
    }
})

const colorScheme = {
    dark: {
        background: '#265AB8',
        text: '#FFFFFF',
        backgroundDisabled: '#40414E',
        textDisabled: '#888888',
    },
    light: {
        background: '#2A6BC8',
        text: '#FFFFFF',
        backgroundDisabled: '#40414E',
        textDisabled: '#888888',
    }
}

DoneButton.propTypes = {
    onPress: PropTypes.func,
    disabled: PropTypes.bool,
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.object]),
    title: PropTypes.string,
};

DoneButton.defaultProps = {
    title: '完成',
}

export default DoneButton;