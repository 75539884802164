import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import React from "react";
import {ScrollView, Switch, View} from "react-native";
import ListItem from "../../components/ListItem";
import Container from "../../components/Container";
import {updateSettingsAsync, useSettings} from "../../core/Settings";
import {getAllPlugins} from "../../plugins";

type Props = NativeStackScreenProps<RootStackParamList, "Plugin">;
const PluginScreen = (props: Props) => {
    const {plugins: enabledPluginIds = {}} = useSettings();
    const allPlugins = getAllPlugins();
    const listItems = [];
    for (let plugin of allPlugins) {
        const value = enabledPluginIds[plugin.id];
        listItems.push(
            <ListItem
                key={plugin.id}
                chevron
                title={plugin.name}
                rightComponent={() => {
                    return (
                        <View style={{justifyContent: 'center'}}>
                            <Switch value={value}
                                    onValueChange={value => {
                                        const update = {...enabledPluginIds};
                                        update[plugin.id] = value
                                        updateSettingsAsync({plugins: update})
                                    }}/>
                        </View>)
                }}
            />
        )
    }
    return (
        <Container>
            <ScrollView>
                {listItems}
            </ScrollView>
        </Container>
    )

}

export default PluginScreen;