import React from "react";
import {View} from "react-native";
import {useTheme} from "@react-navigation/native";
import * as PropTypes from "prop-types";

const Card = ({style, children}) => {
    const {colors} = useTheme();
    return (
        <View style={[
            {backgroundColor: colors.card},
            style,
        ]}>
            {children}
        </View>
    )
};

Card.propTypes = {
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.object]),
}

export default Card;