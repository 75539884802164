import React, {useCallback, useEffect, useState} from 'react';
import {View, ActivityIndicator, Text} from "react-native";
import QRCode from "./QRCode";
import {useTheme} from "@react-navigation/native";

type Props = {
    size?: number,
}

const WECHAT_GROUP_INFO_URL = 'https://wx.feng.to/oa/ygd/wechat-group/'
const WeChatGroupQRCode: React.FC<Props> = (props) => {
    const {size} = props;
    const [loading, setLoading] = useState(true);
    const [value, setValue] = useState('');
    const [error, setError] = useState('');
    const {colors} = useTheme();
    const loadQRCode = useCallback(() => {
        setLoading(true);
        fetch(WECHAT_GROUP_INFO_URL).then(response => response.json()).then(data => {
            const {qrcode} = data;
            setValue(qrcode);
            setLoading(false);
        }).catch(error => {
            setError('加载失败')
            setLoading(false);
        })
    }, [])
    useEffect(() => {
        loadQRCode()
    }, [])
    if (loading) {
        return (
            <View style={{
                width: size,
                height: size,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <ActivityIndicator/>
            </View>
        )
    } else {
        if (error) {
            return (
                <View style={{
                    width: size,
                    height: size,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <Text
                        onPress={loadQRCode}
                        style={{
                            color: colors.text,
                            fontSize: 16,
                        }}>二维码加载失败</Text>
                </View>
            )
        } else {
            return (
                <QRCode value={value} size={size}/>
            )
        }
    }
}

export default WeChatGroupQRCode;