import {DefaultTheme} from '@react-navigation/native';

const DarkTheme = {
    dark: false,
    colors: {
        ...DefaultTheme.colors,
        background: '#FFFFFF',
        card: '#F7F7F8',
        border: '#E5E5E5',
        headerTint: '#FFFFFF',
        headerBackground: '#343540',
    },
};


export default DarkTheme;