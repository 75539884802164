import {useTheme} from "@react-navigation/native";
import {View, Text, Pressable, StyleSheet} from "react-native";
import PropTypes from "prop-types";
import {FontAwesome5} from '@expo/vector-icons';

const CheckBoxListItem = (props) => {
    const {title, onPress, checked} = props;
    const {colors} = useTheme();
    return (
        <Pressable
            onPress={onPress}
            style={{
                backgroundColor: colors.card,
                minHeight: 58,
                paddingLeft: 12,
                paddingRight: 12,
                flexDirection: 'row',
                borderColor: colors.border,
                borderBottomWidth: StyleSheet.hairlineWidth,
            }}>
            <View style={{
                flex: 1,
                justifyContent: 'center',
            }}>
                <Text style={{
                    fontSize: 17,
                    color: colors.text,
                }}>{title}</Text>
            </View>
            <View style={{
                justifyContent: 'center',
            }}>
                {checked && <FontAwesome5 name="check" size={18} color={'#08A94F'}/>}
            </View>
        </Pressable>
    )
};

CheckBoxListItem.propTypes = {
    title: PropTypes.string,
    onPress: PropTypes.func,
    checked: PropTypes.bool
};

export default CheckBoxListItem;