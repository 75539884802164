import {useTheme} from "@react-navigation/native";
import {View, Text, StyleSheet, TouchableOpacity} from "react-native";
import PropTypes from "prop-types";
import render from "../render";
import Chevron from "./Chevron";

const ListItem = (props) => {
    const {title, onPress, chevron, note, rightComponent, style, leftComponent} = props;
    const {colors} = useTheme();
    const ContainerComponent = onPress ? TouchableOpacity : View;
    return (
        <ContainerComponent
            onPress={onPress}
            style={[{
                backgroundColor: colors.card,
                minHeight: 58,
                paddingLeft: 12,
                paddingRight: 12,
                flexDirection: 'row',
                borderColor: colors.border,
                borderBottomWidth: StyleSheet.hairlineWidth,
            }, style]}>
            {leftComponent ? render(leftComponent) : null}
            <View style={{
                flex: 1,
                justifyContent: 'center',
            }}>
                <Text numberOfLines={1} style={{
                    fontSize: 17,
                    color: colors.text,
                }}>{title}</Text>
            </View>
            {rightComponent ? render(rightComponent) :
                <>
                    {note &&
                        <View style={{
                            justifyContent: 'center',
                        }}>
                            <Text style={{
                                color: colors.text,
                                fontSize: 16,
                            }}>{note}</Text>
                        </View>}
                    <View style={{
                        justifyContent: 'center',
                    }}>
                        {chevron && <Chevron/>}
                    </View>
                </>
            }
        </ContainerComponent>
    )
};

ListItem.propTypes = {
    title: PropTypes.string,
    onPress: PropTypes.func,
    chevron: PropTypes.bool,
    note: PropTypes.string,
    rightComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    leftComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]),
};

export default ListItem;