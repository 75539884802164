import {CreateImageRequest} from "../openai/types";
import {Plugin} from "./types";
import {Client, Configuration} from "../openai";
import {getSettingsAsync} from "../core/Settings";

const functionCall: Plugin = {
    id: 'openai-create-image',
    name: '画图',
    description: '"画图"插件将会允许AI根据用户的要求生成图片。',
    functions: [
        {
            func: async (params: CreateImageRequest) => {
                const {apiBaseUrl, apiKey} = await getSettingsAsync();
                const configuration = new Configuration({baseUrl: apiBaseUrl, apiKey});
                const client = new Client(configuration);
                return client.createImageAsync(params);
            },
            meta: {
                name: "create_image",
                description: "Creates an image given a prompt",
                parameters: {
                    type: "object",
                    properties: {
                        prompt: {
                            type: 'string',
                            description: "A text description of the desired image(s). The maximum length is 1000 characters",
                        },
                        size: {
                            type: 'string',
                            description: 'The size of the generated images',
                            enum: ['256x256', '512x512', '1024x1024'],
                        },
                        n: {
                            type: 'number',
                            description: 'The number of images to generate. Must be between 1 and 10'
                        },
                        response_format: {
                            type: 'string',
                            description: 'The format in which the generated images are returned. Must be one of url or b64_json. Supports getting images in URL or base46 formats. Complete URLs are required to access images, URL query parameters cannot be removed'
                        }

                    },
                    required: ['prompt']
                }
            }
        },
    ]
}

export default functionCall;