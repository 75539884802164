import {Plugin} from "./types";

type IpAddressResponse = {
    origin: string,
}

async function getIpAddress(): Promise<IpAddressResponse> {
    const url = 'https://httpbin.ceshiren.com/ip';
    return (await fetch(url).then(response => response.json())) as IpAddressResponse;
}

const plugin: Plugin = {
    id: 'get-ip',
    name: 'IP查询',
    description: '该插件允许AI助理查询当前设备的公网IP地址。',
    functions: [
        {
            func: getIpAddress,
            meta: {
                name: "get_ip",
                description: "get user ip address",
                parameters: {
                    type: "object",
                    properties: {}
                }
            }
        }
    ]
}

export default plugin;