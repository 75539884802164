import AsyncStorage from '@react-native-async-storage/async-storage';

export default class AsyncStorageObject {
    constructor(key = '__@settings') {
        this.key = key;
    }

    async getAsync() {
        const data = await AsyncStorage.getItem(this.key);
        return data ? JSON.parse(data) : {};
    }

    async updateAsync(updateSettings) {
        const settings = await this.getAsync();
        const newSettings = {
            ...settings,
            ...updateSettings,
            updatedAt: new Date().getTime(),
        };
        await AsyncStorage.setItem(this.key, JSON.stringify(newSettings));
        return newSettings;
    }

    async deleteAsync() {
        return AsyncStorage.removeItem(this.key);
    }

    async saveAsync(data) {
        return AsyncStorage.setItem(this.key, JSON.stringify(data))
    }
}
