import {Plugin} from "./types";

type Params = {
    current: string,
    ratesForCurrents?: Array<string>,
}

type Rates = { [key: string]: number };


type BaseExchangeRateResponse = {
    time_last_update_unix: number,
    time_next_update_unix: number,
    time_eol_unix: 0,
    base_code: string,
    rates: Rates,
}

type ExchangeRateResponse = {
    result: string,
    provider: string,
    documentation: string,
    terms_of_use: string,
    time_last_update_unix: number,
    time_last_update_utc: string,
    time_next_update_unix: number,
    time_next_update_utc: string,
    time_eol_unix: 0,
    base_code: string,
    rates: Rates,
}

async function getExchangeRate(params: Params): Promise<ExchangeRateResponse> {
    const {current, ratesForCurrents} = params;
    const url = `https://open.er-api.com/v6/latest/${current}`;
    const result = await fetch(url).then(response => response.json()) as ExchangeRateResponse;
    if (ratesForCurrents && ratesForCurrents.length > 0) {
        const rates: Rates = {}
        for (let code of ratesForCurrents) {
            rates[code] = result.rates[code];
        }
        result.rates = rates;
        return result;
    } else {
        return result;
    }
}

async function getExchangeRateAsPlugin(params: Params): Promise<BaseExchangeRateResponse> {
    const result = await getExchangeRate(params);
    return {
        time_eol_unix: result.time_eol_unix,
        time_last_update_unix: result.time_last_update_unix,
        time_next_update_unix: result.time_next_update_unix,
        base_code: result.base_code,
        rates: result.rates,
    }
}

const functionCall: Plugin = {
    id: 'exchangerate-api',
    name: '汇率',
    description: '该插件允许AI助理查询货币汇率。',
    functions: [
        {
            func: getExchangeRateAsPlugin,
            meta: {
                name: "get_exchange_rates",
                description: "Get exchange rate data which is updated once a day",
                parameters: {
                    type: "object",
                    properties: {
                        current: {
                            type: 'string',
                            description: 'The base currency for the query, represented using ISO 4217 three-letter currency code, e.g., "USD" or "CNY".',
                        },
                        ratesForCurrents: {
                            type: "array",
                            items: {
                                type: 'string',
                            },
                            description: 'A list of currencies to query exchange rates for, represented using ISO 4217 three-letter currency codes, e.g., ["EUR", "GBP", "JPY"].',
                        },
                    }
                }
            }
        },
    ],
}

export {getExchangeRate};
export default functionCall;