import React from 'react';
import {Pressable, StyleSheet, Text, View} from "react-native";
import {Model} from "../../../core/bots/types";
import {useTheme} from "@react-navigation/native";
import {FontAwesome5} from "@expo/vector-icons";
import {GestureResponderEvent} from "react-native/Libraries/Types/CoreEventTypes";
import Badge from "./Badge";
import {getModelInfo} from "../ModelInfo";

type Props = {
    model: Model,
    checked?: boolean,
    onPress?: null | ((event: GestureResponderEvent) => void) | undefined;
}
const ModelSelectItem: React.FC<Props> = (props) => {
    const {
        model,
        onPress,
        checked,
    } = props;
    const {colors} = useTheme();
    const info = getModelInfo(model);
    const badges = [];
    if (info.snapshot) {
        badges.push(<Badge key={'snapshot'} style={{marginLeft: 5}} type={'secondary'}>快照</Badge>)
    }
    if (info.plugin) {
        badges.push(<Badge key={'plugin'} style={{marginLeft: 5}} type={'primary'}>插件</Badge>)
    }
    if (info.deprecate) {
        badges.push(<Badge key={'deprecate'} style={{marginLeft: 5}} type={'warning'}>弃用</Badge>)
    }
    if (info.recommended) {
        badges.push(<Badge key={'recommended'} style={{marginLeft: 5}} type={'info'}>推荐</Badge>)
    }
    if (info.new) {
        badges.push(<Badge key={'new'} style={{marginLeft: 5}} type={'success'}>新模型</Badge>)
    }
    return (
        <Pressable
            onPress={onPress}
            style={{
                backgroundColor: colors.card,
                minHeight: 58,
                paddingLeft: 12,
                paddingRight: 12,
                flexDirection: 'row',
                borderColor: colors.border,
                borderBottomWidth: StyleSheet.hairlineWidth,
            }}>
            <View style={{
                flex: 1,
                flexDirection: "row",
                alignItems: 'center',
            }}>
                <Text style={{
                    fontSize: 17,
                    color: colors.text,
                    marginRight: 2,
                }}>{model}</Text>
                {badges}
            </View>
            <View style={{
                justifyContent: 'center',
            }}>
                {checked && <FontAwesome5 name="check" size={18} color={'#08A94F'}/>}
            </View>
        </Pressable>
    )
}

ModelSelectItem.defaultProps = {
    checked: false,
};

export default ModelSelectItem