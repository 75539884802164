import React, {useState} from 'react';
import {ScrollView, StyleSheet} from "react-native";
import {Container} from "../../base";
import {
    useSettings,
    updateSettingsAsync,
    API_SERVERS,
} from "../../core/Settings";
import Card from "../../components/Card";
import Text from "../../components/Text";
import ApiServerListItem from "./components/ApiServerListItem";
import {ApiServer} from "../../core/types";

const {
    DEFAULT: DEFAULT_SERVER,
    MIRROR_1,
    MIRROR_2,
} = API_SERVERS;

const SelectApiServerScreen = () => {
    const [apiServer, setApiServer] = useState<ApiServer>(DEFAULT_SERVER);
    const {
        apiBaseUrl: baseUrl,
    } = useSettings();
    return (
        <Container>
            <ScrollView>
                <ApiServerListItem apiServer={DEFAULT_SERVER}
                                   checked={baseUrl === DEFAULT_SERVER.baseUrl}
                                   onPress={() => {
                                       updateSettingsAsync({apiBaseUrl: DEFAULT_SERVER.baseUrl});
                                       setApiServer(DEFAULT_SERVER);
                                   }}/>
                <ApiServerListItem apiServer={MIRROR_2}
                                   checked={baseUrl === MIRROR_2.baseUrl}
                                   onPress={() => {
                                       updateSettingsAsync({apiBaseUrl: MIRROR_2.baseUrl});
                                       setApiServer(MIRROR_2);
                                   }}/>
                <ApiServerListItem apiServer={MIRROR_1}
                                   checked={baseUrl === MIRROR_1.baseUrl}
                                   onPress={() => {
                                       updateSettingsAsync({apiBaseUrl: MIRROR_1.baseUrl});
                                       setApiServer(MIRROR_1)
                                   }}/>
                {apiServer.description &&
                    <Card style={styles.card}>
                        <Text style={styles.cardText}>{apiServer.description}</Text>
                    </Card>
                }
            </ScrollView>
        </Container>
    )
};

const styles = StyleSheet.create({
    card: {
        margin: 12,
        marginTop: 20,
        borderRadius: 5,
        padding: 10,
    },
    cardText: {
        fontSize: 16,
        color: '#888888'
    },
})

export default SelectApiServerScreen;