import React from 'react';
import {Animated, Keyboard, StyleSheet, Dimensions, TouchableOpacity, Easing, View} from 'react-native';
import {SafeAreaInsetsContext} from 'react-native-safe-area-context';
import ActionSheetItem, {ITEM_HEIGHT} from "./ActionSheetItem";
import PropTypes from 'prop-types';
import Divider from "./Divider";

const DIVIDER_HEIGHT = 8;
export default class ActionSheet extends React.Component {

    state = {
        visible: false,
        opacity: new Animated.Value(0),
        translateY: new Animated.Value(0),
        options: [],
        optionsContainerHeight: 0,
    };

    show = ({options = []}) => {
        const optionsContainerHeight = options.filter(({divider = false}) => divider).length * DIVIDER_HEIGHT + options.length * ITEM_HEIGHT + this.insets.bottom;
        this.setState({visible: true, options, optionsContainerHeight});
        Keyboard.dismiss();
        Animated.timing(this.state.opacity, {
            toValue: 0.1,
            duration: 100,
            useNativeDriver: true,
        }).start();
        Animated.spring(this.state.translateY, {
            bounciness: 0,
            toValue: -(optionsContainerHeight),
            easing: Easing.quad,
            useNativeDriver: true,
        }).start();
    };

    hide = () => {
        this.setState({visible: false});
        Animated.timing(this.state.opacity, {
            toValue: 0,
            duration: 100,
            useNativeDriver: true,
        }).start();
        Animated.spring(this.state.translateY, {
            bounciness: 0,
            speed: 50,
            toValue: 0,
            useNativeDriver: true,
        }).start(() => {
            this.setState({optionsContainerHeight: 0});
        });
    };

    render() {
        const {visible, opacity, translateY, options, optionsContainerHeight} = this.state;
        const {colorScheme} = this.props;
        const window = Dimensions.get("window");
        const items = [];
        const dividerColor = colorScheme === 'dark' ? '#1E1E1E' : '#F7F7F7';
        for (let i = 0; i < options.length; i++) {
            const {text, onPress, textStyle, divider = false} = options[i];
            if (divider) {
                items.push(<Divider lineWidth={DIVIDER_HEIGHT} color={dividerColor} key={`divider-${i}`}/>);
            }
            const last = i === (options.length - 1);
            items.push(
                <ActionSheetItem
                    text={text}
                    textStyle={textStyle}
                    colorScheme={colorScheme}
                    key={`option-${i}`}
                    onPress={() => {
                        this.hide();
                        if (onPress) {
                            onPress(i);
                        }
                    }} last={last}/>
            )
        }
        return (
            <SafeAreaInsetsContext.Consumer>
                {insets => {
                    this.insets = insets;
                    return (
                        <View style={styles.container} pointerEvents={visible ? 'box-none' : 'none'}>
                            <Animated.View style={[styles.container, {
                                backgroundColor: '#000000',
                                opacity: opacity,
                            }]}>
                                <TouchableOpacity style={{
                                    flex: 1,
                                }} activeOpacity={1} onPress={() => {
                                    this.hide();
                                }}>
                                </TouchableOpacity>
                            </Animated.View>
                            <Animated.View style={{
                                borderTopRightRadius: 12,
                                borderTopLeftRadius: 12,
                                overflow: 'hidden',
                                transform: [{translateY: translateY}],
                                position: 'absolute',
                                width: window.width,
                                height: optionsContainerHeight,
                                bottom: -optionsContainerHeight,
                                right: 0,
                            }}>
                                {items}
                            </Animated.View>
                        </View>
                    )
                }}
            </SafeAreaInsetsContext.Consumer>
        );
    }

}

ActionSheet.propTypes = {
    colorScheme: PropTypes.oneOf(['light', 'dark']),
};

const window = Dimensions.get("window");
const styles = StyleSheet.create({
    container: {
        position: "absolute",
        width: window.width,
        height: window.height,
        top: 0,
        right: 0,
    }
});
