import React, {useEffect, useState} from "react";
import {ScrollView, StyleSheet, Keyboard, View, Text, TouchableOpacity} from "react-native";
import Container from "../../components/Container";
import DoneButton from "../../components/DoneButton";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import PromptInput from "../DrawScreen/components/PromptInput";
import {Alert} from "../../base";
import {generateId} from "../../core/utils";

type Props = NativeStackScreenProps<RootStackParamList, "SetSystemMessage">;

const SetSystemMessageScreen = ({navigation, route}: Props) => {
    const [prompt, setPrompt] = useState(route.params?.content || '');

    useEffect(() => {
        const disabled = prompt.trim().length === 0;
        navigation.setOptions({
            headerRight: () => {
                return (
                    <DoneButton
                        title={'完成'}
                        disabled={disabled}
                        onPress={() => {
                            Keyboard.dismiss();
                            navigation.navigate('Chat', {
                                systemMessage: {
                                    id: generateId(),
                                    user: 'system',
                                    text: prompt,
                                }
                            })
                        }}/>
                )
            }
        })
    }, [navigation, prompt]);

    return (
        <Container>
            <ScrollView contentContainerStyle={{
                paddingBottom: 12,
            }}>
                <PromptInput
                    placeholder={'请输入系统消息'}
                    autoFocus
                    value={prompt}
                    onChangeText={setPrompt}/>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingRight: 12,
                    paddingTop: 10,
                }}>
                    {route.params?.content ?
                        <TouchableOpacity
                            onPress={() => {
                                Alert.alert('确定删除', '确定删除该消息', [
                                    {text: '取消'},
                                    {text: '确定', onPress: () => navigation.navigate('Chat', {systemMessage: null})}
                                ])

                            }}>
                            <Text style={{
                                fontSize: 17,
                                color: 'red',
                            }}>删除</Text>
                        </TouchableOpacity>
                        : null}
                </View>
            </ScrollView>
        </Container>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        width: '100%',
        maxHeight: 200,
        minHeight: 58,
        borderColor: 'gray',
        borderWidth: 1,
        padding: 10,
    },
});
export default SetSystemMessageScreen;