import React, {useCallback, useEffect, useState} from 'react';
import * as SplashScreen from 'expo-splash-screen';
import {View, useColorScheme} from "react-native";
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import ChatScreen from "./screens/ChatScreen";
import {NavigationContainer} from "@react-navigation/native";
import DarkTheme from "./themes/DarkTheme";
import LightTheme from "./themes/LightTheme";
import SettingsScreen from "./screens/SettingsScreen";
import SetApiKeyScreen from "./screens/SetApiKeyScreen";
import {DEFAULT_SETTINGS, getSettingsAsync} from "./core/Settings";
import WelcomeScreen from "./screens/WelcomeScreen";
import {AppProvider} from "./components/AppContext";
import ThemeScreen from "./screens/ThemeScreen";
import GetApiKeyScreen from "./screens/GetApiKeyScreen";
import {Root} from "./base";
import SetModelScreen from "./screens/SetModelScreen";
import SelectApiServerScreen from "./screens/SelectApiServerScreen";
import DrawScreen from "./screens/DrawScreen";
import DrawResultScreen from "./screens/DrawResultScreen";
import OpenAiBalanceScreen from "./screens/OpenAiBalanceScreen";
import HistoryScreen from "./screens/HistoryScreen";
import HelpScreen from "./screens/HelpScreen";
import ContactDeveloperScreen from "./screens/ContactDeveloperScreen";
import JoinWeChatGroupScreen from "./screens/JoinWeChatGroupScreen";
import WeChatOfficialAccountScreen from "./screens/WeChatOfficialAccountScreen";
import ErrorDetailScreen from "./screens/ErrorDetailScreen";
import {Settings} from "./core/types";
import {RootStackParamList} from "./screens/types";
import FeatureUnlockScreen from "./screens/FeatureUnlockScreen";
import SetSystemMessageScreen from "./screens/SetSystemMessageScreen";
import HappyBirthday20230522Screen from "./screens/HappyBirthday20230522Screen";
import PluginScreen from "./screens/PluginScreen";

SplashScreen.preventAutoHideAsync();
const Stack = createNativeStackNavigator<RootStackParamList>();

export default function AppRoot() {
    const [appIsReady, setAppIsReady] = useState(false);
    const [settings, setSettings] = useState<Settings>(DEFAULT_SETTINGS);
    const systemColorScheme = useColorScheme();
    const {apiKey, theme: userTheme} = settings;
    let theme = LightTheme;
    if (userTheme === 'dark') {
        theme = DarkTheme;
    } else if (userTheme === 'light') {
        theme = LightTheme;
    } else {
        if (systemColorScheme) {
            theme = systemColorScheme === 'dark' ? DarkTheme : LightTheme;
        } else {
            theme = LightTheme;
        }
    }
    const {colors} = theme;

    useEffect(() => {
        async function prepare() {
            try {
                await loadSettings();
            } catch (e) {
                console.warn(e);
            } finally {
                setAppIsReady(true);
            }
        }

        prepare();
    }, []);

    const onLayoutRootView = useCallback(async () => {
        if (appIsReady) {
            // This tells the splash screen to hide immediately! If we call this after
            // `setAppIsReady`, then we may see a blank screen while the app is
            // loading its initial state and rendering its first pixels. So instead,
            // we hide the splash screen once we know the root view has already
            // performed layout.
            await SplashScreen.hideAsync();
        }
    }, [appIsReady]);

    const loadSettings = async () => {
        const settings = await getSettingsAsync();
        setSettings(settings);
    };

    if (!appIsReady) {
        return null;
    }

    // @ts-ignore
    return (
        <View style={{flex: 1}} onLayout={onLayoutRootView}>

            <AppProvider value={{
                loadSettings,
            }}>
                <NavigationContainer theme={theme}>
                    <Root>
                        <Stack.Navigator screenOptions={{
                            headerTintColor: theme.colors.headerTint,
                            headerStyle: {
                                backgroundColor: colors.headerBackground,
                            }
                        }}>
                            {apiKey ?
                                <>
                                    <Stack.Screen name="Chat" component={ChatScreen} options={{
                                        title: 'ChatGPT客户端'
                                    }}/>
                                    <Stack.Screen name="Settings" component={SettingsScreen} options={{
                                        title: '设置'
                                    }}/>
                                    <Stack.Screen name="SetApiKey" component={SetApiKeyScreen} options={{
                                        title: '设置API密钥',
                                        animation: 'slide_from_bottom',
                                    }}/>
                                    <Stack.Screen name="Theme" component={ThemeScreen} options={{
                                        title: '主题',
                                    }}/>
                                    <Stack.Screen name="SetModel" component={SetModelScreen} options={{
                                        title: '设置模型',
                                    }}/>
                                    <Stack.Screen name="GetApiKey" component={GetApiKeyScreen} options={{
                                        title: '获取API密钥'
                                    }}/>
                                    <Stack.Screen name="SelectApiServer" component={SelectApiServerScreen} options={{
                                        title: '选择API服务器'
                                    }}/>
                                    <Stack.Screen name="Draw" component={DrawScreen} options={{
                                        title: 'AI画图'
                                    }}/>
                                    <Stack.Screen name="DrawResult" component={DrawResultScreen} options={{
                                        title: '画图结果'
                                    }}/>
                                    <Stack.Screen name="OpenAiBalance" component={OpenAiBalanceScreen} options={{
                                        title: '账单和用量'
                                    }}/>
                                    <Stack.Screen name="History" component={HistoryScreen} options={{
                                        title: '历史会话'
                                    }}/>
                                    <Stack.Screen name="Help" component={HelpScreen} options={{
                                        title: '帮助与反馈'
                                    }}/>
                                    <Stack.Screen name="ContactDeveloper" component={ContactDeveloperScreen} options={{
                                        title: '联系开发者'
                                    }}/>
                                    <Stack.Screen name="WeChatOfficialAccount" component={WeChatOfficialAccountScreen}
                                                  options={{title: '微信公众号'}}/>
                                    <Stack.Screen name="JoinWeChatGroup" component={JoinWeChatGroupScreen} options={{
                                        title: '微信群'
                                    }}/>
                                    <Stack.Screen name="ErrorDetail" component={ErrorDetailScreen} options={{
                                        title: '错误详情'
                                    }}/>
                                    <Stack.Screen name="FeatureUnlock" component={FeatureUnlockScreen} options={{
                                        title: '功能解锁',
                                        animation: 'slide_from_bottom',
                                    }}/>
                                    <Stack.Screen name="SetSystemMessage" component={SetSystemMessageScreen} options={{
                                        title: '设置系统消息',
                                        animation: 'slide_from_bottom',
                                    }}/>
                                    <Stack.Screen name="Plugin" component={PluginScreen} options={{
                                        title: '插件',
                                        animation: 'slide_from_bottom',
                                    }}/>
                                    <Stack.Screen name="HappyBirthday20230522" component={HappyBirthday20230522Screen}
                                                  options={{
                                                      headerShown: false,
                                                      title: '生日快乐',
                                                      animation: 'slide_from_bottom',
                                                  }}/>
                                </>
                                :
                                <>
                                    <Stack.Screen name="Welcome" component={WelcomeScreen} options={{
                                        title: 'ChatGPT客户端'
                                    }}/>
                                    <Stack.Screen name="SelectApiServer" component={SelectApiServerScreen} options={{
                                        title: '选择API服务器'
                                    }}/>
                                    <Stack.Screen name="GetApiKey" component={GetApiKeyScreen} options={{
                                        title: '获取API密钥'
                                    }}/>
                                    <Stack.Screen name="ErrorDetail" component={ErrorDetailScreen} options={{
                                        title: '错误详情'
                                    }}/>
                                    <Stack.Screen name="ContactDeveloper" component={ContactDeveloperScreen} options={{
                                        title: '联系开发者'
                                    }}/>
                                </>
                            }
                        </Stack.Navigator>
                    </Root>
                </NavigationContainer>
            </AppProvider>
        </View>
    );
}