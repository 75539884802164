import React, {useEffect, useRef, useState} from 'react';
import {Keyboard, ScrollView, View} from 'react-native';
import Container from "../components/Container";
import ListItemInput from "../components/ListItemInput";
import DoneButton from "../components/DoneButton";
import {useSettings, getSettingsAsync, updateSettingsAsync, API_SERVERS} from "../core/Settings";
import Loading from "../components/Loading";
import {isValidApiKeyFormat, validateApiKey} from "../core/validateApiKey";
import NavFreeApiKeyButton from "../components/NavFreeApiKeyButton";
import {Alert} from "../base";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "SetApiKey">;
const SetApiKeyScreen = ({navigation}: Props) => {
    const [text, setText] = useState('');
    const [originalText, setOriginalText] = useState('');
    const [loading, setLoading] = useState(false);
    const settings = useSettings();

    useEffect(() => {
        getSettingsAsync().then(settings => {
            const {apiKey = ''} = settings;
            setText(apiKey);
            setOriginalText(apiKey);
        });
    }, [])

    useEffect(() => {
        const apiKey = text.trim();
        const disabled = loading || apiKey.length === 0;
        navigation.setOptions({
            headerRight: (headerTint) => {
                return (
                    <DoneButton
                        disabled={disabled}
                        onPress={async () => {
                            Keyboard.dismiss();
                            if (!isValidApiKeyFormat(apiKey)) {
                                Alert.alert('输入错误', '当输入不是API密钥，请重新输入');
                            } else {
                                setLoading(true);
                                try {
                                    const {apiBaseUrl, timeout} = settings;
                                    const baseUrl = apiKey.startsWith('vk') ? API_SERVERS.MIRROR_1.baseUrl : apiBaseUrl;
                                    const validate = await validateApiKey(apiKey, {
                                        baseUrl,
                                        timeout,
                                    });
                                    if (validate) {
                                        await updateSettingsAsync({apiKey});
                                        setLoading(false);
                                        navigation.goBack();
                                    } else {
                                        setLoading(false);
                                        Alert.alert('无效的API密钥', `API密钥${apiKey.substring(0, 5) + '******' + apiKey.substring(apiKey.length - 3)}无效，请重新输入`);
                                    }
                                } catch (err) {
                                    setLoading(false);
                                    const error = err as Error;
                                    if (error.name === 'TimeoutError') {
                                        Alert.alert('请求超时', '网络请求超时，请检查你的网络环境后重试', [
                                            {
                                                text: '详情',
                                                onPress: () => navigation.navigate('ErrorDetail', {code: 12000})
                                            },
                                            {text: '确定'},
                                        ]);
                                    } else if (error.message === 'Failed to fetch') {
                                        Alert.alert('连接失败', '无法连接到服务器，请检查你的网络环境后重试', [
                                            {
                                                text: '详情',
                                                onPress: () => navigation.navigate('ErrorDetail', {code: 12001})
                                            },
                                            {text: '确定'},
                                        ]);
                                    } else {
                                        Alert.alert(error.name, error.message);
                                    }
                                }
                            }
                        }}/>
                )
            }
        });
    }, [text, originalText, loading, settings])

    return (
        <Container>
            <ScrollView>
                <ListItemInput value={text} onChangeText={text => setText(text)} autoFocus/>
                <View style={{
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    paddingTop: 12,
                    paddingRight: 12,
                }}>
                    <NavFreeApiKeyButton/>
                </View>
            </ScrollView>
            {loading && <Loading/>}
        </Container>
    )
};

export default SetApiKeyScreen;