import React from 'react';
import {ActivityIndicator, StyleSheet, View} from 'react-native';
import Markdown from 'react-native-markdown-display';
import BotAvatar from "./BotAvatar";
import {useTheme} from "@react-navigation/native";
import FailedReason from "./FailedReason";
import Text from '../Text';

type Props = {
    text: string,
    failed?: boolean,
    failedReason?: string,
    usingPlugin?: string,
}

const BotMessage: React.FC<Props> = (props) => {
    const {text, failed, failedReason, usingPlugin} = props;
    const {colors, dark} = useTheme();
    const backgroundColor = dark ? '#444653' : '#F7F7F8';
    const borderColor = dark ? '#2A2B31' : '#E5E5E5';

    const markdownStyles = StyleSheet.create({
        text: {
            color: colors.text,
            fontSize: 16,
        },
        bullet_list: {
            color: colors.text,
        },
        ordered_list: {
            color: colors.text,
        },
        table: {
            borderWidth: 1,
            borderColor: '#D9D9E2',
            marginVertical: 10,
        },
        thead: {
            backgroundColor: dark ? '#656772' : '#F5F5F6',
            fontWeight: 'bold',
        },
        th: {
            padding: 10,
        },
        td: {
            padding: 10,
            borderBottomWidth: 0,
            borderBottomColor: '#D9D9E2',
        },
        tr: {
            borderBottomWidth: 1,
            borderBottomColor: '#D9D9E2',
        },
        code_inline: {
            backgroundColor: '#343540',
            color: '#FFFFFF',
            padding: 2,
            paddingRight: 5,
            paddingLeft: 5,
            borderRadius: 2,
            fontSize: 13,
            marginRight: 3,
            marginLeft: 3,
            borderWidth: 0,
        },
    });

    return (
        <View style={[styles.container, {backgroundColor, borderColor}]}>
            <BotAvatar/>
            <View style={styles.content}>
                {text && text.length > 0 ?
                    // @ts-ignore
                    <Markdown style={markdownStyles}>{text}</Markdown> :
                    !failed ?
                        <View style={{flexDirection: 'row', alignItems: 'center', paddingTop: 10}}>
                            <ActivityIndicator/>
                        </View> : null
                }
                {failed && <FailedReason reason={failedReason}/>}
                {usingPlugin &&
                    <View style={{
                        marginTop: 20,
                        flexDirection: 'row',
                    }}>
                        <View style={{
                            backgroundColor: dark ? '#4e72b8' : '#7bbfea',
                            padding: 5,
                            paddingTop: 2,
                            paddingBottom: 2,
                            borderRadius: 5,
                        }}>
                            <Text style={{
                                fontSize: 14,
                                color: '#FFFFFF',
                            }}>{`执行插件：${usingPlugin}`}</Text>
                        </View>
                    </View>
                }
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 16,
        paddingVertical: 12,
        flexDirection: 'row',
        alignItems: 'flex-start',
        borderTopWidth: StyleSheet.hairlineWidth,
    },
    content: {
        flex: 1,
        marginLeft: 12,
    },
});

BotMessage.defaultProps = {
    failed: false,
}

export default BotMessage;
