import React from 'react';
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {Feather} from '@expo/vector-icons';
import {useNavigation, useTheme} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {RootStackParamList} from "../../screens/types";

type Props = {
    text: string,
}

const SystemMessage: React.FC<Props> = ({text}) => {
    const {colors, dark} = useTheme();
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const backgroundColor = dark ? '#444653' : '#F7F7F8';
    const headerBackgroundColor = dark ? '#202124' : '#343540';
    return (
        <View style={[styles.container, {backgroundColor: colors.background}]}>
            <View style={styles.content}>
                <View style={{
                    borderRadius: 6,
                    backgroundColor: backgroundColor,
                    alignSelf: 'stretch',
                    overflow: 'hidden',
                }}>
                    <View style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingRight: 12,
                        paddingLeft: 12,
                        height: 35,
                        backgroundColor: headerBackgroundColor,
                    }}>
                        <Text style={{
                            fontSize: 17,
                            fontWeight: 'bold',
                            color: '#FFFFFF',
                        }}>系统消息</Text>
                        <View style={{flexDirection: 'row'}}>
                            <TouchableOpacity onPress={() => navigation.navigate('SetSystemMessage', {content: text})}>
                                <Feather name="edit" size={18} color={'#FFFFFF'}/>
                            </TouchableOpacity>
                        </View>
                    </View>
                    <View style={{
                        paddingRight: 12,
                        paddingLeft: 12,
                        paddingTop: 12,
                        paddingBottom: 12,
                    }}>
                        <Text style={[styles.text, {color: colors.text}]}>{text}</Text>
                    </View>
                </View>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 16,
        paddingVertical: 12,
        flexDirection: 'row',
        alignItems: 'flex-start',
        // borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: '#CCCCCC',
    },
    content: {
        flex: 1,
    },
    text: {
        fontSize: 16,
        color: '#FFFFFF',
    },
});


export default SystemMessage;