import {ApiError, Client, Configuration} from "../openai";
import {RequestOptions} from "../openai/types";

interface Options extends RequestOptions {
    baseUrl?: string,
}

const validateApiKey = async (apiKey: string, options: Options = {}) => {
    if (!isValidApiKeyFormat(apiKey)) {
        return false;
    }
    const {baseUrl, ...fetchOptions} = options;
    const configuration = new Configuration({apiKey, baseUrl});
    const client = new Client(configuration);
    try {
        await client.listModelsAsync(fetchOptions);
        return true;
    } catch (error) {
        if (error instanceof ApiError && error.code === ApiError.CODE.INVALID_API_KEY) {
            return false;
        } else {
            throw error;
        }
    }
}

function isValidApiKeyFormat(apiKey: string) {
    const regex = /^(sk|vk)-[a-zA-Z0-9]{48}$/;
    return regex.test(apiKey);
}

export {validateApiKey, isValidApiKeyFormat}