import React from 'react';
import {Text as RNText, TextProps} from 'react-native';
import {useTheme} from "@react-navigation/native";

const Text: React.FC<TextProps> = (props) => {
    const {children, style, ...otherProps} = props;
    const theme = useTheme();
    return (
        <RNText {...otherProps} style={[{
            color: theme.colors.text,
            fontSize: 17,
        }, style]}>{children}</RNText>
    )
}

export default Text;