import React from 'react';
import {StyleSheet, View, ColorValue, ViewStyle} from "react-native";

type Props = {
    children: React.ReactElement,
    size?: number,
    backgroundColor?: ColorValue,
    style?: ViewStyle,
}

const AvatarContainer: React.FC<Props> = (props) => {
    const {children, size, backgroundColor, style} = props;
    return (
        <View style={[styles.container, {
            width: size,
            height: size,
            backgroundColor,
        }, style]}>
            {children}
        </View>)
};

const styles = StyleSheet.create({
    container: {
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
});

AvatarContainer.defaultProps = {
    size: 40,
}

export default AvatarContainer;