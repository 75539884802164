import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import UserAvatar from "./UserAvatar";
import {useTheme} from "@react-navigation/native";

type Props = {
    text:string,
}

const UserMessage:React.FC<Props> = ({text}) => {
    const {colors} = useTheme();

    return (
        <View style={[styles.container, {backgroundColor: colors.background}]}>
            <UserAvatar/>
            <View style={styles.content}>
                <Text style={[styles.text, {color: colors.text}]}>{text}</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        backgroundColor: '#343540',
        paddingHorizontal: 16,
        paddingVertical: 12,
        flexDirection: 'row',
        alignItems: 'flex-start',
    },
    avatar: {
        width: 30,
        height: 30,
        backgroundColor: '#53AB68',
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
    },
    avatarText: {
        fontSize: 13,
        color: "#FFFFFF",
    },
    content: {
        flex: 1,
        marginLeft: 12,
    },
    text: {
        fontSize: 16,
        marginTop:8,
    },
});

export default UserMessage;
