import React from 'react';
import {View, ViewStyle} from 'react-native';

type Props = {
    style?: ViewStyle,
    children?: React.ReactNode,
}

const Container:React.FC<Props> = (props) => {
    const {style, children} = props;
    return (
        <View style={[{flex: 1}, style]}>
            {children}
        </View>
    )
};

export default Container;