import React from "react";
import {ScrollView, View} from "react-native";
import Container from "../components/Container";
import Text from '../components/Text';
import AppConstants from "../constants/AppConstants";
import QRCode from "../components/QRCode";
import Env from "../constants/Env";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "GetApiKey">;
const GetApiKeyScreen = (props: Props) => {
    return (
        <Container>
            <ScrollView contentContainerStyle={{
                padding: 12,
                paddingTop: 20,
            }}>
                <View style={{
                    alignItems: 'center',
                }}>
                    <Text style={{
                        fontSize: 17,
                    }}>微信扫一扫公众号二维码</Text>
                    <Text style={{
                        fontSize: 17,
                    }}>回复"my"获取API密钥</Text>
                    <View style={{
                        marginTop: 15,
                    }}>
                        <QRCode
                            size={150}
                            value={AppConstants.WECHAT_OFFICIAL_ACCOUNT_QR}
                        />
                    </View>
                    {Env.WECHAT_BROWSER && <Text style={{
                        fontSize: 17,
                        marginTop: 12,
                    }}>长按上方二维码</Text>}
                </View>
            </ScrollView>
        </Container>
    )
};

export default GetApiKeyScreen;