import Container from "../components/Container";
import Text from "../components/Text"
import {ScrollView, View} from "react-native";
import AppConstants from "../constants/AppConstants";
import QRCode from "../components/QRCode";
import Env from "../constants/Env";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "ContactDeveloper">;
const ContactDeveloperScreen = (props: Props) => {
    return (
        <Container>
            <ScrollView contentContainerStyle={{
                padding: 12,
                paddingTop: 20,
            }}>
                <View style={{
                    alignItems: 'center',
                }}>
                    <Text style={{
                        fontSize: 17,
                    }}>{ADD_DEVELOPER_TITLE}</Text>
                    <Text style={{
                        fontSize: 17,
                    }}>请备注"客户端"</Text>
                    <View style={{
                        marginTop: 30,
                    }}>
                        <QRCode
                            size={200}
                            value={AppConstants.DEVELOPER_WECHAT_QR_VALUE}
                        />
                    </View>
                </View>
            </ScrollView>
        </Container>
    )
};

const ADD_DEVELOPER_TITLE = Env.WECHAT_BROWSER ? '长按二维码添加开发者微信' : '微信扫二维码添加开发者微信';

export default ContactDeveloperScreen;