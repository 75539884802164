import React, {useState} from 'react';
import * as PropTypes from "prop-types";
import {useTheme} from "@react-navigation/native";
import {Pressable, StyleSheet, Text} from "react-native";

const BaseButton = (props) => {
    const {darkColors, lightColors, onPress, title, style, titleStyle, disabled, touchOpacity, children} = props;
    const [touch, setTouch] = useState(false);
    const {dark} = useTheme();
    const colors = dark ? darkColors : lightColors;
    return (
        <Pressable
            disabled={disabled}
            onPress={onPress}
            onPressIn={() => setTouch(true)}
            onPressOut={() => setTouch(false)}
            style={[
                styles.container,
                {
                    backgroundColor: disabled ? colors.backgroundDisabled : colors.background,
                    opacity: touch ? touchOpacity : 1
                },
                style,
            ]}
        >
            {children ? children :
                <Text style={[
                    styles.title,
                    {color: disabled ? colors.textDisabled : colors.text},
                    titleStyle,
                ]}>{title}</Text>
            }
        </Pressable>
    )
}

const styles = StyleSheet.create({
    container: {
        paddingRight: 8,
        paddingLeft: 8,
        paddingTop: 5,
        paddingBottom: 5,
        borderRadius: 3,
    },
    title: {
        fontSize: 16,
    }
})

const buttonColorsType = PropTypes.shape({
    background: PropTypes.string,
    backgroundDisabled: PropTypes.string,
    text: PropTypes.string,
    textDisabled: PropTypes.string,
});
BaseButton.propTypes = {
    darkColors: buttonColorsType,
    lightColors: buttonColorsType,
    title: PropTypes.string,
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.object]),
    titleStyle: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.object]),
    disabled: PropTypes.bool,
    touchOpacity: PropTypes.number,
}

BaseButton.defaultProps = {
    touchOpacity: 0.5,
    darkColors: {
        background: '#265AB8',
        text: '#FFFFFF',
        backgroundDisabled: '#40414E',
        textDisabled: '#888888',
    },
    lightColors: {
        background: '#2A6BC8',
        text: '#FFFFFF',
        backgroundDisabled: '#40414E',
        textDisabled: '#888888',
    }
}

export default BaseButton;