import React, {useEffect, useState} from 'react';
import {Animated, Easing, Platform} from "react-native";

type Props = {}
const Gift: React.FC<Props> = () => {
    const [aniValue, setAniValue] = useState(new Animated.Value(0));
    useEffect(() => {
        const ani1 = Animated.timing(aniValue, {
            toValue: 1,
            duration: 500,
            easing: Easing.linear,
            useNativeDriver: Platform.OS != 'web',
            delay: 500,
        })
        const ani2 = Animated.timing(aniValue, {
            toValue: 0,
            duration: 500,
            easing: Easing.linear,
            useNativeDriver: Platform.OS !== 'web',
        })
        Animated.loop(Animated.sequence([ani1, ani2])).start()
    }, []);
    return (
        <Animated.Image source={require('../../../../assets/images/birthday2023_gift.png')} style={{
            width: 200,
            height: 200,
            transform: [{
                rotate: aniValue.interpolate({
                    inputRange: [0, 0.5, 1],
                    outputRange: ['0deg', '15deg', '-15deg']
                })
            }]
        }}/>
    )
};


export default Gift;
