import AsyncStorage from '@react-native-async-storage/async-storage';

export default class AsyncStorageCache {
    constructor(storageKey = '__@asyncStorage@cache') {
        this.storageKey = storageKey;
    }

    async getAsync(key, defaultValue) {
        return getAsync(key, defaultValue, this.storageKey);
    }

    async setAsync(key, value, expiresIn) {
        return setAsync(key, value, expiresIn, this.storageKey);
    }

    async deleteAsync(key) {
        return deleteAsync(key, this.storageKey);
    }

    async clearAsync() {
        return clearAsync(this.storageKey);
    }

    async minimizeAsync() {
        return minimizeAsync(this.storageKey);
    }
}

const getCacheDataAsync = async (storageKey) => {
    const value = await AsyncStorage.getItem(storageKey);
    return value ? JSON.parse(value) : {};
};

const saveCacheDateAsync = async (data, storageKey) => {
    return AsyncStorage.setItem(storageKey, JSON.stringify(data));
};

const removeExpiredData = (cache) => {
    for (let [key, {e = 0}] of Object.entries(cache)) {
        if (e < new Date().getTime()) {
            delete  cache[key];
        }
    }
};

const minimizeAsync = async (storageKey) => {
    const cache = await getCacheDataAsync(storageKey);
    removeExpiredData(cache);
    return saveCacheDateAsync(cache, storageKey);
};

const getAsync = async (key, defaultValue = null, storageKey) => {
    const cache = await getCacheDataAsync(storageKey);
    const cacheValue = cache[key];
    if (cacheValue === undefined) {
        return defaultValue;
    } else {
        const {v, e} = cacheValue;
        if (new Date().getTime() <= e) {
            return v;
        } else {
            await deleteAsync(key, storageKey);
            return defaultValue;
        }
    }
};

const setAsync = async (key, value, expiresIn, storageKey) => {
    const cache = await getCacheDataAsync(storageKey);
    cache[key] = {
        v: value,
        e: new Date().getTime() + expiresIn,
    };
    return saveCacheDateAsync(cache, storageKey);
};

const deleteAsync = async (key, storageKey) => {
    const cache = await getCacheDataAsync(storageKey);
    delete cache[key];
    return saveCacheDateAsync(cache, storageKey);
};

const clearAsync = async (storageKey) => {
    return AsyncStorage.removeItem(storageKey);
};

