import Container from "../../components/Container";
import {Image, ScrollView, useWindowDimensions, View} from "react-native";
import Link from "../../components/Link";
import Text from "../../components/Text";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";

type Props = NativeStackScreenProps<RootStackParamList, "DrawResult">;

const DrawResultScreen = ({route}: Props) => {
    const {params: {url, prompt, base64} = {}} = route;
    const window = useWindowDimensions();
    return (
        <Container>
            <ScrollView contentContainerStyle={{
                paddingBottom: 12,
            }}>
                <View style={{
                    paddingTop: 20,
                    paddingRight: 12,
                    paddingLeft: 12,
                }}>
                    <Text style={{
                        marginBottom: 12,
                        fontSize: 17,
                    }}>{prompt}</Text>
                    <Link url={url}>查看原图</Link>
                    <Image
                        source={{uri: base64 ? `data:image/png;base64,${base64}` : url}}
                        style={{
                            height: window.width - 24,
                            width: window.width - 24,
                            marginTop: 12,
                        }}/>
                </View>
            </ScrollView>
        </Container>
    )
};

export default DrawResultScreen;