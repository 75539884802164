import * as React from 'react';
import {Button} from "../components/Alert/types";
import Alert from "../components/Alert";

export const alertRef = React.createRef<Alert>();

export function alert(title: string, message: string, buttons?: Button[]) {
    alertRef.current?.show(title, message, buttons);
}

export default {
    alert,
}