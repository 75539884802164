import React from 'react';
import {TouchableOpacity, StyleSheet} from "react-native";
import * as PropTypes from "prop-types";

const HeaderButton = ({children, onPress, onLongPress, style}) => {
    return (
        <TouchableOpacity onPress={onPress} onLongPress={onLongPress} style={[styles.container, style]}>
            {children}
        </TouchableOpacity>
    )
};

const styles = StyleSheet.create({
    container: {
        paddingRight: 6,
        paddingLeft: 6,
    }
});

HeaderButton.propTypes = {
    onPress: PropTypes.func,
    onLongPress: PropTypes.func,
    children: PropTypes.any,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]),
}

export default HeaderButton;