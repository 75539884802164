import React, {useEffect, useState} from 'react';
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import {
    Image,
    View,
    Text,
    ImageBackground,
    TouchableOpacity,
    ActivityIndicator,
    useWindowDimensions
} from "react-native";
import {Audio, AVPlaybackStatus} from 'expo-av';
import {useSafeAreaInsets} from "react-native-safe-area-context";
import {FontAwesome} from '@expo/vector-icons';
import {useFonts} from "expo-font";
import {useAssets} from 'expo-asset';
import TitleCard from "./components/TitleCard";
import {AVPlaybackSource} from "expo-av/src/AV";
import ColorCyclingView from "../../components/ColorCyclingView";
import Gift from "./components/Gift";
import Star from "./components/Star";

type Props = NativeStackScreenProps<RootStackParamList, "HappyBirthday20230522">;

const titles = [
    '祝小刘生日快乐',
    '祝小刘快乐常在',
    '祝小刘健健康康',
    '祝小刘心想事成',
]

const songs = [
    {uri: 'http://music.163.com/song/media/outer/url?id=427610321.mp3'},
    {uri: 'http://music.163.com/song/media/outer/url?id=27570622.mp3'},
    {uri: 'http://music.163.com/song/media/outer/url?id=1836033745.mp3'},//那一刻心动
    {uri: 'http://music.163.com/song/media/outer/url?id=85571.mp3'}, //我们俩
    {uri: 'http://music.163.com/song/media/outer/url?id=4873540.mp3'}, //你
    require('../../../assets/sound/山海邮差.mp3'),
]


const HappyBirthday20230522Screen = ({navigation}: Props) => {
    const [sound, setSound] = useState<Audio.Sound>();
    const [open, setOpen] = useState(false);
    const [titleIndex, setTitleIndex] = useState(0);
    const [playbackStatus, setPlaybackStatus] = useState<AVPlaybackStatus | undefined>();
    const insets = useSafeAreaInsets();
    const window = useWindowDimensions();
    const [fontsLoaded] = useFonts({
        'WXkatonglemiao': require('../../../assets/fonts/WXkatonglemiao.ttf'),
    });
    const [assets] = useAssets([
        require('../../../assets/images/birthday2023_bg.jpg'),
        require('../../../assets/images/birthday2023_cake.gif'),
        require('../../../assets/images/birthday2023_gift.png'),
        require('../../../assets/images/birthday2023_star.png'),
        require('../../../assets/sound/山海邮差.mp3'),
    ])

    const isReady = fontsLoaded && assets !== undefined && sound;

    async function playSound() {
        if (sound) {
            await sound.playAsync();
        }
    }

    async function loadAndPlaySound(source: AVPlaybackSource) {
        if (sound) {
            await sound.unloadAsync();
            Audio.Sound.createAsync(source, {isLooping: true}, status => {
                setPlaybackStatus(status);
            }).then(async ({sound}) => {
                setSound(sound);
                await sound.playAsync();
            });
        }
    }

    useEffect(() => {
        Audio.Sound.createAsync(songs[0], {isLooping: true}, (status) => {
            setPlaybackStatus(status);
        }).then(({sound}) => {
            setSound(sound);
        });
    }, []);

    useEffect(() => {
        return sound
            ? () => {
                sound.unloadAsync();
            }
            : undefined;
    }, [sound]);

    if (!isReady) {
        return (
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: "#FFFFFF",
            }}>
                <ActivityIndicator size={"large"}/>
                <Text style={{
                    fontSize: 18,
                    marginTop: 15,
                }}>正在加载资源</Text>
            </View>
        )
    }

    if (!open) {
        return (
            <ColorCyclingView
                colorOutputRange={['#B0C4DE', '#87CEEB', '#ADD8E6', '#E1F5FE']}
                animatedInputRange={[0, 0.33, 0.66, 1]}
                duration={20000}
                style={{
                    flex: 1,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                <Text style={{
                    fontSize: 30,
                    fontFamily: "WXkatonglemiao",
                }}>点击👇打开</Text>
                <TouchableOpacity onPress={() => {
                    playSound().then(() => {
                        setOpen(true);
                    });
                }}>
                    <Gift/>
                </TouchableOpacity>
                <TouchableOpacity
                    style={{
                        position: 'absolute',
                        right: 12,
                        top: 12 + insets.top,
                    }}
                    onPress={() => navigation.goBack()}
                >
                    <FontAwesome name="close" size={24} color="black"/>
                </TouchableOpacity>
            </ColorCyclingView>
        )
    }

    return (
        <View style={{flex: 1}}>
            <ImageBackground source={require('../../../assets/images/birthday2023_bg.jpg')} style={{
                flex: 1,
                paddingTop: insets.top,
                paddingBottom: insets.bottom,
            }}>
                <View style={{flex: 1, justifyContent: 'center'}}>
                    <View style={{
                        position: 'absolute',
                        width: '100%',
                        top: 0,
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 150,
                    }}>

                        <TitleCard title={titles[titleIndex]}/>
                    </View>
                    <View>
                        <TouchableOpacity onPress={() => {
                            setTitleIndex((titleIndex + 1) % titles.length);
                        }}>
                            <Image source={require('../../../assets/images/birthday2023_cake.gif')} style={{
                                alignSelf: 'center',
                                width: 200,
                                height: 200,
                                marginTop: 20,
                            }}/>
                        </TouchableOpacity>
                    </View>
                    <Star
                        onPress={() => loadAndPlaySound(songs[0])}
                        style={{position: 'absolute', top: 30, left: 20}}
                    />
                    <Star
                        onPress={() => loadAndPlaySound(songs[1])}
                        style={{position: 'absolute', top: 30, right: 20}}
                    />
                    <Star
                        onPress={() => loadAndPlaySound(songs[2])}
                        style={{position: 'absolute', top: (window.height - 40) / 2, left: 10}}
                    />
                    <Star
                        onPress={() => loadAndPlaySound(songs[3])}
                        style={{position: 'absolute', top: (window.height - 40) / 2, right: 10}}
                    />
                    <Star
                        onPress={() => loadAndPlaySound(songs[4])}
                        style={{position: 'absolute', bottom: 30, left: 20}}
                    />
                    <Star
                        onPress={() => loadAndPlaySound(songs[5])}
                        style={{position: 'absolute', bottom: 30, right: 20}}
                    />
                    <TouchableOpacity
                        style={{
                            position: 'absolute',
                            right: 12,
                            top: 12,
                        }}
                        onPress={() => navigation.goBack()}
                    >
                        <FontAwesome name="close" size={24} color="black"/>
                    </TouchableOpacity>
                </View>
            </ImageBackground>
        </View>
    )
}

export default HappyBirthday20230522Screen;