import CreateImagePlugin from "./CreateImagePlugin";
import WeatherPlugin from "./WeatherPlugin";
import TimePlugin from "./TimePlugin";
import GetIpPlugin from "./GetIpPlugin";
import WebSearchPlugin from "./WebSearchPlugin";
import {Plugin} from "./types";
import LocationPlugin from "./LocationPlugin";
import DevicePlugin from "./DevicePlugin";
import ExchangeRatePlugin from "./ExchangeRatePlugin";
import getIpPlugin from "./GetIpPlugin";


function getAllPlugins(): Array<Plugin> {
    return [
        CreateImagePlugin,
        DevicePlugin,
        ExchangeRatePlugin,
        WeatherPlugin,
        TimePlugin,
        getIpPlugin,
        LocationPlugin,
        WebSearchPlugin,
    ];
}

function getPlugin(id: string): Plugin | undefined {
    const plugins = getAllPlugins();
    for (let plugin of plugins) {
        if (plugin.id === id) {
            return plugin;
        }
    }
}

export {
    ExchangeRatePlugin,
    DevicePlugin,
    WeatherPlugin,
    LocationPlugin,
    TimePlugin,
    CreateImagePlugin,
    WebSearchPlugin,
    getAllPlugins,
    getPlugin,
}
