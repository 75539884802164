import Text from "../Text";
import {View} from "react-native";
import React from "react";

const ListItemGroupTitle = (props) => {
    const {children} = props;
    return (
        <View style={{
            height: 40,
            justifyContent: 'flex-end',
            paddingLeft: 12,
            paddingBottom: 5,
        }}>
            <Text style={{
                fontSize: 15,
                color: '#888888',
            }}>{children}</Text>
        </View>
    )
};

export default ListItemGroupTitle;