import React, {useEffect, useState} from 'react';
import {Animated, Easing, ViewStyle} from "react-native";

type Props = {
    style: ViewStyle,
    children?: React.ReactNode,
    colorOutputRange: number[] | string[],
    animatedInputRange: number[],
    duration?: number,
}
const ColorCyclingView: React.FC<Props> = (props) => {
    const {style, children, colorOutputRange, animatedInputRange, duration} = props;
    const [colorValue, setColorValue] = useState(new Animated.Value(0));
    useEffect(() => {
        const animated1 = Animated.timing(colorValue, {
            toValue: 1,
            duration: duration,
            easing: Easing.linear,
            useNativeDriver: false,
        })
        const animated2 = Animated.timing(colorValue, {
            toValue: 0,
            duration: duration,
            easing: Easing.linear,
            useNativeDriver: false,
        })
        const animated = Animated.loop(Animated.sequence([animated1, animated2]));
        animated.start();
        return () => {
            animated.stop();
        }
    }, []);
    return (
        <Animated.View style={[{
            flex: 1,
            backgroundColor: colorValue.interpolate({
                inputRange: animatedInputRange,
                outputRange: colorOutputRange,
            })
        }, style]}>
            {children}
        </Animated.View>
    )
}

ColorCyclingView.defaultProps = {
    colorOutputRange: ['pink', 'blue'],
    duration: 2000,
}

export default ColorCyclingView;