export interface FetchOptions extends RequestInit {
    timeout?: number,
}

const fetchWithTimeout = (url: RequestInfo, options?: FetchOptions): Promise<Response> => {
    const {timeout = 60000, ...fetchOptions} = options || {};
    return Promise.race([
        fetch(url, fetchOptions),
        new Promise((_, reject) =>
            setTimeout(() => reject(new TimeoutError('Request timed out')), timeout)
        )
    ]) as Promise<Response>;
};

export class TimeoutError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'TimeoutError';
    }
}

export default fetchWithTimeout;