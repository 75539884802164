import React, {useState, useEffect} from 'react';
import {DeviceEventEmitter} from 'react-native';
import AsyncStorageObject from "./AsyncStorageObject";
import {ApiServer, SessionMemory, Settings, UpdateSettings} from "./types";

const STORAGE_KEY = '@settings';
const event = {SETTINGS_UPDATED: 'settings_updated'};
const _settings = new AsyncStorageObject(STORAGE_KEY);

const DEFAULT_SESSION_MEMORY: SessionMemory = {
    enabled: true,
}

const DEFAULT_SETTINGS: Settings = {
    theme: 'auto',
    apiKey: '',
    apiBaseUrl: 'https://api.openai.com',
    model: 'gpt-3.5-turbo',
    timeout: 60000,
    sessionMemory: DEFAULT_SESSION_MEMORY,
    plugins: {},
};

const updateSettingsAsync = async (updateSettings: UpdateSettings): Promise<Settings> => {
    const result = await _settings.updateAsync({...updateSettings, updatedAt: new Date().getTime()});
    const settings = {...DEFAULT_SETTINGS, ...result};
    DeviceEventEmitter.emit(event.SETTINGS_UPDATED, settings);
    return result;
};

const getSettingsAsync = async (): Promise<Settings> => {
    const result = await _settings.getAsync();
    return {...DEFAULT_SETTINGS, ...result};
};

const useSettings = (initialSettings: any = DEFAULT_SETTINGS): Settings => {
    const [settings, setSettings] = useState<Settings>(initialSettings);
    useEffect(() => {
        getSettingsAsync().then(settings => {
            setSettings(settings);
        });
    }, []);
    useEffect(() => {
        const listener = DeviceEventEmitter.addListener(event.SETTINGS_UPDATED, settings => {
            setSettings(settings);
        });
        return () => listener.remove();
    }, []);
    return settings;
};

const MIRROR_API_SERVER_2: ApiServer = {
    baseUrl: 'https://api.openai.feng.to',
    name: '友观点专用[优化]',
    description: '由"友观点"提供的API服务器。如果当前网络环境访问OpenAI服务器存在问题，可以选择此服务器代替官方服务器',
};

const MIRROR_API_SERVER: ApiServer = {
    baseUrl: 'https://wx.feng.to/openai_api',
    name: '友观点专用',
    description: '由"友观点"提供的API服务器。 支持vk开头的虚拟API密钥，如果当前网络环境访问OpenAI服务器存在问题，可以选择此服务器代替官方服务器，但速度较慢',
};
const API_SERVER: ApiServer = {
    name: 'OpenAI',
    baseUrl: 'https://api.openai.com',
    description: 'OpenAI官方API服务器，速度较快，但是在某些地区无法正常访问',
};

const API_SERVERS = {
    DEFAULT: API_SERVER,
    MIRROR_1: MIRROR_API_SERVER,
    MIRROR_2: MIRROR_API_SERVER_2,
}

function getApiServerByUrl(url: string | undefined) {
    if (url) {
        const [server] = Object.values<ApiServer>(API_SERVERS).filter(server => server.baseUrl === url);
        return server;
    }
}

export {
    useSettings,
    DEFAULT_SETTINGS,
    getSettingsAsync,
    updateSettingsAsync,
    getApiServerByUrl,
    API_SERVERS,
};