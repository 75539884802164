import Container from "../components/Container";
import React, {useContext} from "react";
import {ScrollView, useColorScheme} from "react-native";
import {useSettings, updateSettingsAsync} from "../core/Settings";
import {AppContext} from "../components/AppContext";
import CheckBoxListItem from "../components/CheckBoxListItem";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "Theme">;
const ThemeScreen = (props: Props) => {
    const {theme} = useSettings({});
    const {loadSettings} = useContext(AppContext);
    const systemColorScheme = useColorScheme();
    return (
        <Container>
            <ScrollView>
                <CheckBoxListItem
                    checked={theme === 'dark'}
                    title={'深色'}
                    onPress={() => {
                        updateSettingsAsync({theme: 'dark'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <CheckBoxListItem
                    checked={theme === 'light'}
                    title={'普通'}
                    onPress={() => {
                        updateSettingsAsync({theme: 'light'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                {systemColorScheme &&
                    <CheckBoxListItem
                        checked={theme === 'auto'}
                        title={'跟随系统'}
                        onPress={() => {
                            updateSettingsAsync({theme: 'auto'}).then(() => {
                                loadSettings();
                            });
                        }}/>
                }
            </ScrollView>
        </Container>
    )

};

export default ThemeScreen;