import Text from "../../../components/Text";
import Link from "../../../components/Link";
import {StyleSheet, View} from "react-native";
import React from "react";
import {useSafeAreaInsets} from "react-native-safe-area-context";

type Props = {};
const Footer: React.FC<Props> = (props) => {
    const insets = useSafeAreaInsets();
    return (
        <View style={{
            alignItems: 'center',
            paddingBottom: insets.bottom + 5,
        }}>
            <Text style={styles.footerText}>
                本服务由<Link style={styles.footerLink} url={'https://blog.feng.to'}>友观点</Link>开发并运营
            </Text>
        </View>
    )
}

const styles = StyleSheet.create({
    footerLink: {
        fontSize: 15,
    },
    footerText: {
        fontSize: 15,
    }
});

export default Footer;