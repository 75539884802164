import React from 'react';
import {Text, Animated, Dimensions, ColorValue} from 'react-native';
import {SafeAreaInsetsContext} from 'react-native-safe-area-context';
import {ShowToastParams} from "./types";

type Props = {};

type State = {
    text: string,
    position: string,
    duration: number,
    visible: boolean,
    type: string,
}

class Toast extends React.Component<Props, State> {

    state = {
        text: 'text',
        position: 'top',
        duration: 1000,
        visible: false,
        type: 'default',
    };

    show = ({text = '', position = 'bottom', duration = 1000, type = 'default'}: ShowToastParams) => {
        this.setState({
            text,
            position,
            duration,
            visible: true,
            type,
        });
        setTimeout(() => {
            this.setState({visible: false});
        }, duration)
    };

    render() {
        const {text, visible, position, type} = this.state;
        const colors = typeColors[type];
        if (!visible) {
            return null;
        }
        const window = Dimensions.get('window');
        return (
            <SafeAreaInsetsContext.Consumer>
                {insets => {
                    const {bottom = 0, top = 0} = {...insets};
                    return (
                        <Animated.View style={[{
                            position: 'absolute',
                            bottom: 10 + bottom,
                            width: window.width - 40,
                            height: 50,
                            left: 20,
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent:'center',
                            backgroundColor: colors.background,
                            paddingLeft: 10,
                            paddingRight: 10,
                            borderRadius: 5,
                        },
                            position === 'bottom' ? {
                                bottom: 10 + bottom,
                            } : {
                                top: 10 + top,
                            }

                        ]}>
                            <Text style={{
                                color: colors.text,
                                fontSize: 16,
                            }}>{text}</Text>
                        </Animated.View>
                    );
                }}
            </SafeAreaInsetsContext.Consumer>
        );
    }
}

type Colors = {
    background: ColorValue,
    text: ColorValue,
}

const typeColors: { [key: string]: Colors } = {
    default: {
        background: 'rgba(0,0,0,0.8)',
        text: '#FFFFFF',
    },
    danger: {
        background: 'rgba(217,83,79,0.8)',
        text: '#FFFFFF',
    },
    warning: {
        background: 'rgba(240,173,78,0.8)',
        text: '#FFFFFF',
    },
    success: {
        background: 'rgba(92,184,92,0.8)',
        text: '#FFFFFF',
    }
};

export default Toast;