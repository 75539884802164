import {Plugin} from "./types";
import moment from 'moment';

const TimePlugin: Plugin = {
    id:'time',
    name: '时间查询',
    description: '"时间查询"插件允许AI助理查询当前时间。',
    functions: [
        {
            func: (): unknown => {
                return {'time': moment().format('YYYY-MM-DD HH:mm:ss')}
            },
            meta: {
                name: "get_current_time",
                description: "Get current time",
                parameters: {
                    type: "object",
                    properties: {}
                }
            }
        },
    ]
}

export default TimePlugin;