import React from "react";
import {StyleSheet, Text, TouchableOpacity, View} from "react-native";
import {MaterialIcons} from "@expo/vector-icons";
import {useTheme} from "@react-navigation/native";

type Props = {
    onPressDelete: () => void;
    selectCount: number,
}
const BAR_HEIGHT = 60;

const SelectionBottomBar:React.FC<Props> = (props) => {
    const {onPressDelete, selectCount = 0} = props;
    const {dark, colors} = useTheme();
    return (
        <View style={{
            height: BAR_HEIGHT,
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            borderTopColor: dark ? '#44464A' : '#D8D8D8',
            borderTopWidth: StyleSheet.hairlineWidth,
            backgroundColor: colors.background,
            flexDirection: 'row',
            alignItems: 'center',
            paddingRight: 12,
            paddingLeft: 12,
        }}>
            <View style={{flex: 1}}>

            </View>
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                <Text style={{
                    color: colors.text,
                    fontSize: 17,
                }}>{selectCount > 0 ? `已选择${selectCount}个会话` : '选择会话'}</Text>
            </View>
            <View style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-end',
            }}>
                <TouchableOpacity
                    disabled={selectCount === 0}
                    style={{
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                    onPress={onPressDelete}>
                    <MaterialIcons name="delete" size={24} color={colors.text}/>
                </TouchableOpacity>
            </View>
        </View>
    )
}

export {BAR_HEIGHT};
export default SelectionBottomBar;