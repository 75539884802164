import React from 'react';
import {useTheme} from "@react-navigation/native";
import {MaterialIcons} from '@expo/vector-icons';

const Chevron = ({style}) => {
    const {colors} = useTheme();
    return (
        <MaterialIcons style={style} name="keyboard-arrow-right" size={24} color={colors.text}/>
    );
}

export default Chevron;