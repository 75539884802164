import React from "react";
import {ActivityIndicator, StyleSheet, View, Text, useWindowDimensions} from "react-native";
import {useHeaderHeight} from "@react-navigation/elements";
import * as PropTypes from "prop-types";

const Loading = (props) => {
    const {text} = props;
    const headerHeight = useHeaderHeight();
    const window = useWindowDimensions();
    return (
        <View style={{
            ...StyleSheet.absoluteFillObject,
            backgroundColor: 'rgba(0,0,0, 0.3)',
            paddingBottom: headerHeight,
        }}>
            <View style={{
                flex: 1,
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <View style={{
                    backgroundColor: '#000000',
                    width: 100,
                    height: 100,
                    borderRadius: 5,
                    justifyContent: 'center',
                    alignItems: 'center',
                }}>
                    <ActivityIndicator color={'#FFFFFF'}/>
                    <Text style={{
                        marginTop: 12,
                        color: '#F1F1F1',
                        fontSize: 16,
                    }}>{text}</Text>
                </View>
            </View>
        </View>
    )
}

Loading.propTypes = {
    text: PropTypes.string,
}

Loading.defaultProps = {
    text: '请稍等',
}

export default Loading;