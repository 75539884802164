import AsyncStorage from '@react-native-async-storage/async-storage';


const KEY = '@serviceTermsVersion';

async function agreeTerms(version: string) {
    return AsyncStorage.setItem(KEY, version)
}

function isTermsAgreed(version: string): Promise<Boolean> {
    return AsyncStorage.getItem(KEY).then(data => {
        return data == version;
    });
}

export {
    agreeTerms,
    isTermsAgreed,
}