export default Object.freeze({
    LICENSE_PUBLIC_KEY: `-----BEGIN PUBLIC KEY-----
MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAv0/jllwTBSEV23bxb2z5
3hVN9uiugmN55kGZHCIbSYh0zXhsGPPPARTilOxz/LVT/wcrHEYU29JxwG6XTAXT
E5orBeoPf77O4J8m341ajiF94V5aMK6Q8eojb79qtRoqEWRVP09GT3ME7mlh7Ytn
LXC/QwiEgGeiSLmRGD3nTZHPIdoQloywnL6A1vLKH7YR79zE0mx2uKR+EDMpe7Pf
vvJ2BQHo3QNyTuw5R7D+3L47biGuBU85zONq87762XVlGZ9eykeQpE5qGPKtpHI1
4svl7jIzw0XKNAtBuTLScpebQklPyfteoU3bFOny1AR5rigy9lGLxp3AUbKt090c
7CNUVtNRoS1MR6w+2GcUEMOY4hFX5Fx4e3zYVJLGer+qHlCRw1C01HCTsl06a3hL
EJ+VP+ANHFxMeVhQlVHuoOoI9KDO+8l7EDBYVzZ+VwCExhkP5Mz67Yleo93HU7ew
nlnTioJegoIOBC3iDN0bOu4xyQtJJ2Su75RMftO10E0mwJqnfm4FHxYJJfbguCfk
jNOapS74pZq54YUjiTnzVsd+rM1j+/b3UtShLtT5X9zE9K016Hhg+2KKfHD2sL1I
AiQKWan3V2a8wiyDE96spLxXvlvW1u8vz7TN7zdVjmOOnLEw0iizk59qMNV+8VAM
aEdoAjN+qvlVs6givPMHOgECAwEAAQ==
-----END PUBLIC KEY-----`,
    VK_MAX_REQUEST_PER_DAY: 8,
    DEVELOPER_WECHAT_QR_VALUE: "https://u.wechat.com/EM9yraud8prjaHTjrPlormU",
    WECHAT_OFFICIAL_ACCOUNT_QR: "http://weixin.qq.com/r/QRH17WvE6TaWrS_O90RT",
    WECHAT_OFFICIAL_ACCOUNT_NAME: "友个观点",
    WECHAT_GROUP_NAME: "ChatGPT客户端交流群",
    SERVICE_TERMS_VERSION: '20230705',
    SERVICE_ITEMS_URL: 'https://blog.feng.to/chatgpt-client-terms-of-service/',
    PRIVACY_POLICY_URL: 'https://blog.feng.to/chatgpt-client-privacy-policy/',
});