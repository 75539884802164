import {getSettingsAsync} from "../../core/Settings";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";

type Navigation = NativeStackNavigationProp<RootStackParamList, 'Chat'>;

function checkEasterEgg(navigation: Navigation) {
    const today = new Date();
    if (today.getFullYear() === 2023 && today.getMonth() === 4 && today.getDate() === 22) {
        getSettingsAsync().then(({apiKey}) => {
            if (apiKey.startsWith('sk-BvMZuJM0') && apiKey.endsWith('ByD9XA9')) {
                navigation.navigate('HappyBirthday20230522');
            }
        })
    }
}

export default checkEasterEgg;