import React, {useContext, useEffect, useState} from 'react';
import {View, ScrollView, Keyboard, StyleSheet, TouchableOpacity} from "react-native";
import * as Clipboard from 'expo-clipboard';
import Checkbox from 'expo-checkbox';
import Text from '../../components/Text'
import ListItemInput from "../../components/ListItemInput";
import DoneButton from "../../components/DoneButton";
import {useSettings, API_SERVERS, updateSettingsAsync, getApiServerByUrl} from "../../core/Settings";
import {AppContext} from "../../components/AppContext";
import {isValidApiKeyFormat, validateApiKey} from "../../core/validateApiKey";
import Container from "../../components/Container";
import Loading from "../../components/Loading";
import NavFreeApiKeyButton from "../../components/NavFreeApiKeyButton";
import {Alert} from "../../base";
import Link from "../../components/Link";
import AppConstants from "../../constants/AppConstants";
import Env from "../../constants/Env";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import WeChatGroupQRCode from "../../components/WeChatGroupQRCode";
import {useSafeAreaInsets} from "react-native-safe-area-context";
import Footer from "./components/Footer";
import QRCode from "../../components/QRCode";
import {agreeTerms} from "../../core/ServiceTerms";

type Props = NativeStackScreenProps<RootStackParamList, "Welcome">;
const WelcomeScreen = ({navigation}: Props) => {
    const [apiKey, setApiKey] = useState('');
    const [loading, setLoading] = useState(false);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const {loadSettings} = useContext(AppContext);
    const settings = useSettings();
    const insets = useSafeAreaInsets();
    useEffect(() => {
        Clipboard.getStringAsync().then(text => {
            if (isValidApiKeyFormat(text)) {
                setApiKey(text);
            }
        }).catch(e => {
            console.warn(e.message);
        })
    }, []);
    useEffect(() => {
        const disabled = loading || apiKey.trim().length === 0;
        navigation.setOptions({
            headerRight: () => {
                return (
                    <DoneButton
                        disabled={disabled}
                        onPress={() => {
                            Keyboard.dismiss();
                            if (!isTermsChecked) {
                                Alert.alert('请阅读并勾选条款', '你需要同意条款才能使用本应用', [{text: '确定'}]);
                                return;
                            }
                            if (!isValidApiKeyFormat(apiKey)) {
                                Alert.alert('输入错误', '当输入不是API密钥，请重新输入');
                                setApiKey('');
                            } else {
                                setLoading(true);
                                const {apiBaseUrl, timeout} = settings;
                                const baseUrl = apiKey.startsWith('vk') ? API_SERVERS.MIRROR_1.baseUrl : apiBaseUrl;
                                const options = {
                                    baseUrl,
                                    timeout,
                                }
                                validateApiKey(apiKey, options).then(isValid => {
                                    setLoading(false);
                                    if (isValid) {
                                        agreeTerms(AppConstants.SERVICE_TERMS_VERSION).then(() => {
                                            return updateSettingsAsync({apiKey});
                                        }).then(() => {
                                            loadSettings();
                                        })
                                    } else {
                                        Alert.alert('无效的API密钥', `API密钥${apiKey.substring(0, 5) + '******' + apiKey.substring(apiKey.length - 3)}无效，请重新输入`);
                                        setApiKey('');
                                    }
                                }).catch(error => {
                                    setLoading(false);
                                    if (error.name === 'TimeoutError') {
                                        Alert.alert('请求超时', '网络请求超时，请检查您的网络后重试', [
                                            {
                                                text: '详情',
                                                onPress: () => navigation.navigate('ErrorDetail', {code: 12000})
                                            },
                                            {text: '确定'},
                                        ]);
                                    } else if (error.message === 'Failed to fetch') {
                                        Alert.alert('连接失败', '无法连接到服务器，请检查你的网络环境后重试', [
                                            {
                                                text: '详情',
                                                onPress: () => navigation.navigate('ErrorDetail', {code: 12001})
                                            },
                                            {text: '确定'},
                                        ]);
                                    } else {
                                        Alert.alert(error.constructor.name, error.message);
                                    }
                                })
                            }
                        }}/>
                )
            }
        })
    }, [navigation, apiKey, loading, settings, isTermsChecked]);

    return (
        <Container>
            <ScrollView>
                <View style={{
                    padding: 20,
                }}>
                    <Text style={styles.title}>请输入API密钥</Text>
                </View>
                <ListItemInput
                    value={apiKey}
                    onChangeText={text => setApiKey(text)}
                    placeholder={'请填写API密钥，例如: sk-xxxxxxx'}
                />
                <View style={styles.termsContainer}>
                    <Checkbox value={isTermsChecked} onValueChange={setIsTermsChecked}/>
                    <Text onPress={() => {
                        setIsTermsChecked(!isTermsChecked);
                    }} style={{
                        fontSize: 13,
                        marginLeft: 5,
                    }}>同意<Link
                        style={styles.termsLink}
                        url={AppConstants.SERVICE_ITEMS_URL}
                    >《ChatGPT客户端服务条款》</Link>
                        <Link
                            style={styles.termsLink}
                            url={AppConstants.PRIVACY_POLICY_URL}
                        >《隐私政策》</Link></Text>
                </View>
                <View style={styles.inputTipContainer}>
                    <View style={{
                        flexDirection: 'row',
                    }}>
                        <Text style={{
                            fontSize: 15,
                        }}>API服务器: <Link
                            onPress={() => navigation.navigate('SelectApiServer')}
                            style={{
                                fontSize: 15,
                            }}>{getApiServerByUrl(settings.apiBaseUrl)?.name}</Link></Text>
                    </View>
                    <NavFreeApiKeyButton/>
                </View>
                <View style={styles.infoContainer}>
                    <Text
                        style={{color: '#FFFFFF'}}>还没有OpenAI账号？找客户端开发者购买才靠谱！一步到位助您用上本客户端！</Text>
                    <TouchableOpacity
                        style={styles.contactButton}
                        onPress={() => navigation.navigate('ContactDeveloper')}>
                        <Text style={styles.contactButtonText}>联系开发者</Text>
                    </TouchableOpacity>
                </View>
                <View style={styles.qrCodesContainer}>
                    <View style={styles.qrContainer}>
                        <Text style={styles.qrTitle}>微信群</Text>
                        <WeChatGroupQRCode size={150}/>
                    </View>
                    <View style={styles.qrContainer}>
                        <Text style={styles.qrTitle}>公众号"友个观点"</Text>
                        <QRCode value={AppConstants.WECHAT_OFFICIAL_ACCOUNT_QR} size={150}/>
                    </View>
                </View>
                <Text style={styles.qrFooterText}>{QR_FOOTER_TEXT}</Text>
            </ScrollView>
            <Footer/>
            {loading && <Loading/>}
        </Container>
    )
};

const QR_FOOTER_TEXT = Env.WECHAT_BROWSER ? `长按二维码加群并关注公众号` : `微信扫二维码加群并关注公众号`;

const styles = StyleSheet.create({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        alignSelf: 'center',
    },
    inputTipContainer: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: 12,
        paddingLeft: 12,
        paddingRight: 12,
    },
    termsContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 12,
        paddingTop: 5,
    },
    termsLink: {
        fontSize: 13,
    },
    infoContainer: {
        margin: 12,
        marginTop: 40,
        padding: 8,
        borderRadius: 5,
        backgroundColor: '#17a2b8',
    },
    contactButton: {
        backgroundColor: '#007bff',
        width: 85,
        height: 30,
        borderRadius: 5,
        justifyContent: 'center',
        alignItems: 'center',
        alignSelf: 'flex-end',
        marginTop: 5,
    },
    contactButtonText: {
        color: '#FFFFFF',
        fontSize: 14,
    },
    qrCodesContainer: {
        flexDirection: 'row',
        marginTop: 30,
        padding: 12,
        alignItems: 'center',
    },
    qrContainer: {
        flex: 1,
        alignItems: 'center',
    },
    qrTitle: {
        fontSize: 17,
        marginBottom: 20,
    },
    qrFooterText: {
        alignSelf: 'center',
        marginTop: 10,
    }
})

export default WelcomeScreen;