import Container from "../../components/Container";
import React, {useContext} from "react";
import {ScrollView, StyleSheet, View} from "react-native";
import {useSettings, updateSettingsAsync} from "../../core/Settings";
import {AppContext} from "../../components/AppContext";
import Link from "../../components/Link";
import Card from "../../components/Card";
import Text from "../../components/Text";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import ModelSelectItem from "./components/ModelSelectItem";
import {getModelInfo} from "./ModelInfo";

type Props = NativeStackScreenProps<RootStackParamList, "SetModel">;
const SetModelScreen = (props: Props) => {
    const {model = 'gpt-3.5-turbo'} = useSettings();
    const {loadSettings} = useContext(AppContext);
    const info = getModelInfo(model)
    return (
        <Container>
            <ScrollView>
                <ModelSelectItem
                    model={'gpt-4'}
                    checked={model === 'gpt-4'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-4'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-4-32k'}
                    checked={model === 'gpt-4-32k'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-4-32k'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-4-0613'}
                    checked={model === 'gpt-4-0613'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-4-0613'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-4-32k-0613'}
                    checked={model === 'gpt-4-32k-0613'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-4-32k-0613'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-3.5-turbo'}
                    checked={model === 'gpt-3.5-turbo'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-3.5-turbo'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-3.5-turbo-16k'}
                    checked={model === 'gpt-3.5-turbo-16k'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-3.5-turbo-16k'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-3.5-turbo-0613'}
                    checked={model === 'gpt-3.5-turbo-0613'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-3.5-turbo-0613'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-3.5-turbo-16k-0613'}
                    checked={model === 'gpt-3.5-turbo-16k-0613'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-3.5-turbo-16k-0613'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <ModelSelectItem
                    model={'gpt-3.5-turbo-0301'}
                    checked={model === 'gpt-3.5-turbo-0301'}
                    onPress={() => {
                        updateSettingsAsync({model: 'gpt-3.5-turbo-0301'}).then(() => {
                            loadSettings();
                        });
                    }}/>
                <Card style={styles.card}>
                    <Text style={styles.cardText}>{info.description}</Text>
                    <View style={{paddingTop: 10}}>
                        <Text style={styles.cardText}>最大请求数: {info.maxRequests}</Text>
                        <Text style={styles.cardText}>训练数据截止于{info.trainingData}</Text>
                    </View>
                </Card>
                <Link style={styles.link} url={'https://platform.openai.com/docs/models/overview'}>了解模型</Link>
            </ScrollView>
        </Container>
    )

};

const styles = StyleSheet.create({
    card: {
        margin: 12,
        marginTop: 20,
        borderRadius: 5,
        padding: 10,
    },
    cardText: {
        fontSize: 16,
        color: '#888888'
    },
    cardFooterText: {
        fontSize: 15,
        marginTop: 5,
    },
    link: {
        alignSelf: 'center',
        fontSize: 15,
    }
})

export default SetModelScreen;