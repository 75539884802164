export default class SimpleModelManager {

    constructor({get, set}) {
        this.getDataAsync = get;
        this.onDataChange = set;
    }


    async allAsync() {
        return this.getDataAsync();
    }

    async deleteAsync(params) {
        const objects = await this.getDataAsync();
        const matchResult = makeMatch(objects, params);
        if (matchResult.length === 0) {
            return 0;
        }
        const result = [];
        for (let {match, index} of matchResult) {
            if (!match) {
                result.push(objects[index]);
            }
        }
        await this.onDataChange(result);
        return matchResult.length;
    }

    async getAsync(params) {
        const result = await this.filterAsync(params);
        if (result.length > 0) {
            return result[0];
        } else {
            return null;
        }
    }

    async filterAsync(params, filter) {
        const objects = await this.getDataAsync();
        if (params === undefined) {
            return objects;
        }
        const matchResult = makeMatch(objects, params);
        const result = [];
        for (let {match, index} of matchResult) {
            const object = objects[index];
            if (match) {
                if (filter) {
                    if (filter(object)) {
                        result.push(object);
                    }
                } else {
                    result.push(objects[index]);
                }
            }
        }
        return result;
    }

    async createAsync(object) {
        const objects = await this.getDataAsync();
        objects.push(object);
        return this.onDataChange(objects);
    }

    async bulkCreateAsync(newObjects) {
        const objects = await this.getDataAsync();
        return this.onDataChange([...objects, ...newObjects]);
    }

    async updateAsync(params, updateObject) {
        const objects = await this.getDataAsync();
        const matchResult = makeMatch(objects, params);
        const result = [];
        const matchedObjects = [];
        for (let {match, index} of matchResult) {
            const o = objects[index];
            if (match) {
                result.push({
                    ...o,
                    ...updateObject,
                });
                matchedObjects.push({
                    ...o,
                    ...updateObject,
                })
            } else {
                result.push(o);
            }
        }
        await this.onDataChange(result);
        return matchedObjects;
    }

    async clearAsync() {
        this.onDataChange([]);
    }
}

const makeMatch = (objects, params) => {
    const conditions = getMatchConditions(params);
    const results = [];
    for (let i = 0; i < objects.length; i++) {
        const object = objects[i];
        let match = true;
        for (let {key, value, op} of conditions) {
            const itemValue = object[key];
            if (op === 'eq') {
                match = itemValue === value;
            } else if (op === 'gt' || op === 'gte' || op === 'lt' || op === 'lte') {
                if (typeof itemValue !== 'number') {
                    match = false;
                } else {
                    if (op === 'gt') {
                        match = itemValue > value;
                    } else if (op === 'gte') {
                        match = itemValue >= value;
                    } else if (op === 'lt') {
                        match = itemValue < value;
                    } else if (op === 'lte') {
                        match = itemValue <= value;
                    }
                }
            }
            if (!match) {
                break;
            }
        }
        results.push({index: i, match});
    }
    return results;
};

const getMatchConditions = (params) => {
    const keys = Object.keys(params);
    const matchConditions = [];
    for (let key of keys) {
        let op = null;
        let name = key;
        if (key.endsWith('__gt')) {
            op = 'gt';
            name = key.substring(0, key.length - 4);
        } else if (key.endsWith('__gte')) {
            op = 'gte';
            name = key.substring(0, key.length - 5);
        } else if (key.endsWith('__lt')) {
            op = 'lt';
            name = key.substring(0, key.length - 4);
        } else if (key.endsWith('__lte')) {
            op = 'lte';
            name = key.substring(0, key.length - 5);
        } else if (key.endsWith('__eq')) {
            op = 'eq';
            name = key.substring(0, key.length - 4);
        } else {
            op = 'eq';
            name = key;
        }
        matchConditions.push({
            op,
            key: name,
            value: params[key],
        })
    }
    return matchConditions;
};

