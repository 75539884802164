import React, {useState} from "react";
import {MaterialIcons} from '@expo/vector-icons';
import ListItem from "../../../components/ListItem";
import {useTheme} from "@react-navigation/native";
import {View} from "react-native";
import Checkbox from 'expo-checkbox';

type Props = {
    title: string,
    onPress: () => void;
    selectMode: boolean,
    onSelectionChange: (value: boolean) => void;
}

const SessionListItem: React.FC<Props> = (props) => {
    const [selected, setSelected] = useState(false);
    const {title, onPress, selectMode, onSelectionChange} = props;
    const {colors} = useTheme();
    return (
        <ListItem
            onPress={() => {
                if (selectMode) {
                    const value = !selected;
                    setSelected(value);
                    onSelectionChange(value);
                } else {
                    onPress && onPress();
                }
            }}
            title={title}
            leftComponent={() => {
                return (
                    <View style={{
                        minWidth: 30,
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginRight: 5,
                    }}>
                        {selectMode && <Checkbox value={selected} onValueChange={setSelected} style={{
                            marginRight: 15,
                        }}/>}
                        <MaterialIcons name="chat-bubble-outline" size={24} color={colors.text}/>
                    </View>
                );
            }}/>
    )
};

export default SessionListItem;