import {Model} from "../../core/bots/types";

type ModelInfo = {
    description: string,
    maxRequests: string,
    trainingData: string,
    snapshot?: boolean,
    deprecate?: boolean,
    new?: boolean,
    recommended?: boolean,
    plugin?: boolean,
}

const modelInfos: { [key: string]: ModelInfo } = {
    'gpt-4': {
        description: '比任何GPT-3.5模型都更强大，能够执行更复杂的任务，并针对聊天进行了优化。 将使用OpenAI最新的模型迭代进行更新。',
        maxRequests: '8,192 tokens',
        trainingData: '2021年9月',
    },
    'gpt-4-0613': {
        description: '2023年6月13日带有函数调用数据的gpt-4快照。与gpt-4不同，该模型不会收到更新，并且会在新版本发布3个月后弃用。',
        maxRequests: '8,192 tokens',
        trainingData: '2021年9月',
        snapshot: true,
        new: true,
        plugin: true,
    },
    'gpt-4-32k': {
        description: '与基本 gpt-4 模式相同的功能，但上下文长度是其 4 倍。 将使用OpenAI最新的模型迭代进行更新。',
        maxRequests: '32,768 tokens',
        trainingData: '2021年9月',
    },
    'gpt-4-32k-0613': {
        description: '2023年6月13日的gpt-4-32快照。与gpt-4-32k不同，该模型不会收到更新，并且会在新版本发布3个月后弃用。',
        maxRequests: '32,768 tokens',
        trainingData: '2021年9月',
        snapshot: true,
        new: true,
        plugin: true,
    },
    'gpt-3.5-turbo': {
        description: '功能最强大的GPT-3.5模型并针对聊天进行了优化，成本仅为text-davinci-003的1/10。 将使用OpenAI最新的模型迭代进行更新。',
        maxRequests: '4,096 tokens',
        trainingData: '2021年9月',
        recommended: true,
    },
    'gpt-3.5-turbo-16k': {
        description: '与标准gpt-3.5-turbo模型具有相同的功能，但上下文是其4倍。',
        maxRequests: '16,384 tokens',
        trainingData: '2021年9月',
        new: true,
        plugin: true,
    },
    'gpt-3.5-turbo-0613': {
        description: '2023年6月13日带有函数调用数据的gpt-3.5-turbo快照。与gpt-3.5-turbo不同，此模型不会收到更新，并且会在新版本发布3个月后弃用。',
        maxRequests: '4,096 tokens',
        trainingData: '2021年9月',
        snapshot: true,
        new: true,
        plugin: true,
    },
    'gpt-3.5-turbo-16k-0613': {
        description: '2023年6月13日的gpt-3.5-turbo-16k快照。与gpt-3.5-turbo-16k不同，此模型不会收到更新，并将在新版本发布3个月后弃用。',
        maxRequests: '16,384 tokens',
        trainingData: '2021年9月',
        snapshot: true,
        new: true,
        plugin: true,
    },
    'gpt-3.5-turbo-0301': {
        description: '2023年3月1日的gpt-3.5-turbo快照。与gpt-3.5-turbo不同，此模型不会收到更新，并且仅在2023年6月1日结束的三个月内提供支持。',
        maxRequests: '4,096 tokens',
        trainingData: '2021年9月',
        snapshot: true,
        deprecate: true,
    },
    'text-davinci-003': {
        description: '相比curie、babbage或ada模型，text-davinci-003可以以更好的质量、更长的输出和一致的指令遵循来完成任何语言任务。 还支持在文本中插入补全。',
        maxRequests: '4,000 tokens',
        trainingData: '2021年6月',
    }
};

function getModelInfo(model: Model) {
    return modelInfos[model]
}

export {getModelInfo}