import React, {useState, useEffect} from 'react';
import {DeviceEventEmitter} from 'react-native';
import AsyncStorageObject from "./AsyncStorageObject";
import {
    Configuration,
    UpdateConfiguration,
} from "./types";

const STORAGE_KEY = '@configuration';
const event = {SETTINGS_UPDATED: 'configuration_updated'};
const _configuration = new AsyncStorageObject(STORAGE_KEY);

const DEFAULT_CONFIGURATION: Configuration = {
    unlockPlugin: false,
};

const updateConfigurationAsync = async (updateConfiguration: UpdateConfiguration): Promise<Configuration> => {
    const result = await _configuration.updateAsync({...updateConfiguration, updatedAt: new Date().getTime()});
    const settings = {...DEFAULT_CONFIGURATION, ...result};
    DeviceEventEmitter.emit(event.SETTINGS_UPDATED, settings);
    return result;
};

const getConfigurationAsync = async (): Promise<Configuration> => {
    const result = await _configuration.getAsync();
    return {...DEFAULT_CONFIGURATION, ...result};
};

const useConfiguration = (initialConfiguration: any = DEFAULT_CONFIGURATION): Configuration => {
    const [configuration, setConfiguration] = useState<Configuration>(initialConfiguration);
    useEffect(() => {
        getConfigurationAsync().then(settings => {
            setConfiguration(settings);
        });
    }, []);
    useEffect(() => {
        const listener = DeviceEventEmitter.addListener(event.SETTINGS_UPDATED, settings => {
            setConfiguration(settings);
        });
        return () => listener.remove();
    }, []);
    return configuration;
};


export {
    useConfiguration,
    DEFAULT_CONFIGURATION,
    getConfigurationAsync,
    updateConfigurationAsync,
};