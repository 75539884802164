import React, {useEffect, useState} from 'react';
import moment from 'moment';
import Container from "../components/Container";
import {getSettingsAsync} from "../core/Settings";
import {Client, Configuration} from "../openai";
import {Alert} from "../base";
import {Linking, ScrollView, View} from "react-native";
import Loading from "../components/Loading";
import ListItem, {ListItemGroupTitle} from "../components/ListItem";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";
import Link from "../components/Link";

type Props = NativeStackScreenProps<RootStackParamList, "OpenAiBalance">;
const OpenAiBalanceScreen = ({navigation}: Props) => {
    const [totalGranted, setTotalGranted] = useState(0);
    const [subscriptionCanceled, setSubscriptionCanceled] = useState(0);
    const [totalUsed, setTotalUsed] = useState(0);
    const [expiresAt, setExpiresAt] = useState(0);
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(true);
    const [planId, setPlanId] = useState('');

    const loadAsync = async () => {
        setLoading(true);
        const settings = await getSettingsAsync();
        const {apiKey = '', apiBaseUrl: baseUrl} = settings;
        if (apiKey.startsWith('vk-')) {
            Alert.alert('操作失败', '共享API密钥不支持该操作');
            navigation.goBack();
            return;
        }
        const configuration = new Configuration({
            apiKey,
            baseUrl,
        });
        const client = new Client(configuration);
        const startDay = moment().startOf('month').format('YYYY-MM-DD');
        const endDay = moment().add(1, 'month').startOf('month').format('YYYY-MM-DD');
        Promise.all([
            client.getBillingUsage(startDay, endDay),
            client.getBillingSubscription(),
        ]).then((result) => {
            const [billingUsage, billingSubscription] = result;
            const {
                plan,
                hard_limit_usd: totalGranted,
                access_until: expiresAt,
                canceled,
            } = billingSubscription;
            const {total_usage: totalUsage = 0} = billingUsage;
            setPlanId(plan.id)
            setTotalGranted(totalGranted);
            setTotalUsed(totalUsage / 100);
            setExpiresAt(expiresAt);
            setSubscriptionCanceled(canceled);
            setSuccess(true);
            setLoading(false);
        }).catch(e => {
            setLoading(false);
            setSuccess(false);
            Alert.alert(e.name, e.message, [
                {text: "取消", onPress: () => navigation.goBack()},
                {text: "重试", onPress: () => loadAsync()}
            ]);
        })
    };

    useEffect(() => {
        loadAsync();
    }, [])

    return (
        <Container>
            <ScrollView>
                {!loading && success &&
                    <>
                        <ListItemGroupTitle>收费方案</ListItemGroupTitle>
                        <ListItem title={'类型'} note={getPlanName(planId)}/>
                        <ListItem title={'状态'}
                                  note={subscriptionCanceled ? `已取消` : '正常'}/>
                        <ListItem title={'限额'} note={`$${formatMoney(totalGranted)}`}/>
                        <ListItem title={'到期时间'} note={expiresAt ? formatDate(expiresAt) : "-"}/>
                        <ListItemGroupTitle>用量</ListItemGroupTitle>
                        <ListItem title={'本月已使用'} note={`$${formatMoney(totalUsed)}`}/>
                        {planId === 'free' &&
                            <ListItem chevron title={'查询可用余额'} onPress={() => {
                                Alert.alert("提示", "OpenAI已禁用余额查询接口，无法在客户端中查询，请前往官网查询", [
                                    {text: '取消'},
                                    {
                                        text: '前往官网', onPress: () => {
                                            Linking.openURL("https://platform.openai.com/account/usage")
                                        }
                                    }
                                ])
                            }}/>
                        }
                        <View style={{
                            alignItems: 'center',
                            marginTop: 10,
                        }}>
                            <Link
                                style={{fontSize: 15}}
                                url={'https://mp.weixin.qq.com/s/tdNHZXDyTJWNTirdah82Eg'}
                            >没有余额?快来使用ChatGPT国内直连吧</Link>
                        </View>
                    </>

                }

            </ScrollView>
            {loading && <Loading text={'查询中'}/>}
        </Container>
    )
}

function formatMoney(amount: number): string {
    let strAmount = amount.toFixed(2).toString()
    let arrAmount = strAmount.split('.')
    let intAmount = arrAmount[0]
    let decAmount = arrAmount[1]
    let arrIntAmount = []
    let count = 0
    for (let i = intAmount.length - 1; i >= 0; i--) {
        arrIntAmount.unshift(intAmount[i])
        count++
        if (count === 3 && i !== 0) {
            arrIntAmount.unshift(',')
            count = 0
        }
    }
    return arrIntAmount.join('') + '.' + decAmount
}

function formatDate(timestamp: number) {
    return moment.unix(timestamp).format('YYYY-MM-DD');
}

type PLAN = {
    title: string;
    verboseName: string;
}

const PLANS: { [key: string]: PLAN } = {
    'payg': {
        title: 'pay-as-you-go',
        verboseName: '随用随付',
    },
    'free': {
        title: "Explore",
        verboseName: '免费试用',
    }
};

function getPlanName(planId: string) {
    return PLANS[planId]?.verboseName ?? planId;
}


export default OpenAiBalanceScreen;