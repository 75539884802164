
interface Options {
    baseUrl?: string,
    apiKey: string,
}

export default class Configuration {
    static DEFAULT_BASE_URL = 'https://api.openai.com';

    baseUrl: string;
    apiKey: string;

    constructor(options: Options) {
        const {baseUrl = Configuration.DEFAULT_BASE_URL, apiKey} = options;
        this.baseUrl = baseUrl;
        this.apiKey = apiKey;
    }

    listModels = () => {
        const url = this.baseUrl + '/v1/models';
        const headers: HeadersInit = {};
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {
            url,
            method: 'GET',
            headers,
        };
    };

    createChatCompletion = () => {
        const url = this.baseUrl + '/v1/chat/completions';
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {
            url,
            method: 'POST',
            headers,
        };
    };

    createCompletion = () => {
        const url = this.baseUrl + '/v1/completions';
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {url, method: 'POST', headers};
    };

    createImage = () => {
        const url = this.baseUrl + '/v1/images/generations';
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {url, method: 'POST', headers};
    };

    getCreditGrants = () => {
        const url = this.baseUrl + '/dashboard/billing/credit_grants';
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {url, method: 'GET', headers};
    }

    getBillingUsage = (startDate: string, endDate: string) => {
        const url = this.baseUrl + `/dashboard/billing/usage?start_date=${startDate}&end_date=${endDate}`;
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {url, method: 'GET', headers};
    }

    getBillingSubscription = () => {
        const url = this.baseUrl + '/dashboard/billing/subscription';
        const headers: HeadersInit = {
            'Content-Type': 'application/json',
        }
        if (this.apiKey) {
            headers['Authorization'] = `Bearer ${this.apiKey}`;
        }
        return {url, method: 'GET', headers};
    }

}
