import {DeviceEventEmitter, Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {SimpleModelManager} from "../base";

const nanoid = Platform.OS === 'web' ? require('nanoid').nanoid : require('nanoid/non-secure').nanoid;
const DATA_KEY = '@chatSessions';
const UPDATED_AT_KEY = '@chatSessions.updatedAt';

const Event = {CHAT_SESSIONS_UPDATED: 'chat_sessions_updated'};

export interface ChatSessionInit {
    id?: string,
    title?: string,
    messages?: any[],
}

export default class ChatSession {

    id?: string;
    title?: string;
    messages?: any[];
    time?: number;

    constructor(init: ChatSessionInit = {}) {
        const {id, title, messages} = init
        this.id = id;
        this.title = title;
        this.messages = messages
    }

    static objects = new SimpleModelManager({
        set: async (data: any) => {
            const result = await AsyncStorage.multiSet([
                [UPDATED_AT_KEY, `${new Date().getTime()}`],
                [DATA_KEY, JSON.stringify(data)],
            ]);
            DeviceEventEmitter.emit(Event.CHAT_SESSIONS_UPDATED, data);
            return result;
        },
        get: async () => {
            const data = await AsyncStorage.getItem(DATA_KEY);
            return data ? JSON.parse(data) : [];
        }
    });

    getData() {
        if (!this.id) {
            this.id = nanoid();
        }
        if (!this.time) {
            this.time = new Date().getTime();
        }
        return {...this};
    }

    async saveAsync() {
        const data = this.getData();
        const {id} = data;
        const result = await ChatSession.objects.getAsync({id});
        const create = !result;
        if (create) {
            return ChatSession.objects.createAsync(data);
        } else {
            return ChatSession.objects.updateAsync({id}, data);
        }
    }
}