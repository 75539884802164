import AvatarContainer from "./AvatarContainer";
import ChatGPTSvgIcon from "../ChatGPTSvgIcon";
import React from "react";

type Props = {
    size?: number,
}

const BotAvatar:React.FC<Props> = ({size}) => {
    return (
        <AvatarContainer backgroundColor={"#48A081"} size={size}>
            <ChatGPTSvgIcon size={24} color={'#FFFFFF'}/>
        </AvatarContainer>
    )
}

export default BotAvatar;