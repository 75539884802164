import {Circle, Svg} from "react-native-svg";
import {useTheme} from "@react-navigation/native";
import React from "react";


export type Status = 'pending' | 'ok' | 'error';

export interface StatusIconProps {
    size?: number;
    status: Status;
}

const StatusIcon: React.FC<StatusIconProps> = (props) => {
    const {size, status} = props;
    const {dark} = useTheme();
    const colors = dark ? darkColors : lightColors;
    return (
        <Svg width={size} height={size} viewBox={'0 0 24 24'} fill={colors[status]}>
            <Circle cx={12} cy={12} r={12}/>
        </Svg>
    )
};

const lightColors = {
    pending: '#8C8C8C',
    ok: '#77B756',
    error: '#ED4D3D',
}
const darkColors = {
    pending: '#8C8C8C',
    ok: '#77B756',
    error: '#EE5D4E',
}

StatusIcon.defaultProps = {
    size: 14,
    status: 'pending',
}

export default StatusIcon;