import Container from "../components/Container";
import Text from "../components/Text"
import {ScrollView, View} from "react-native";
import AppConstants from "../constants/AppConstants";
import Env from "../constants/Env";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";
import WeChatGroupQRCode from "../components/WeChatGroupQRCode";

type Props = NativeStackScreenProps<RootStackParamList, "JoinWeChatGroup">;
const JoinWeChatGroupScreen = (props: Props) => {
    return (
        <Container>
            <ScrollView contentContainerStyle={{
                padding: 12,
                paddingTop: 20,
            }}>
                <View style={{
                    alignItems: 'center',
                }}>
                    <Text style={{
                        fontSize: 17,
                    }}>{JOIN_GROUP_TITLE}</Text>
                    <View style={{
                        marginTop: 30,
                    }}>
                        <WeChatGroupQRCode size={200}/>
                    </View>
                </View>
            </ScrollView>
        </Container>
    )
};

const JOIN_GROUP_TITLE = Env.WECHAT_BROWSER ? `长按二维码加入${AppConstants.WECHAT_GROUP_NAME}` : `微信扫二维码加入${AppConstants.WECHAT_GROUP_NAME}`;

export default JoinWeChatGroupScreen;