import ApiError from "./ApiError";
import fetch from "./fetch-with-timeout" ;
import Configuration from "./Configuration";
import {
    ApiRequestParams,
    CreateChatCompletionRequest,
    ChatCompletionResponse,
    CompleteRequestBody,
    CreateImageRequest,
    RequestOptions, ImagesResponse
} from "./types";


export default class Client {

    configuration: Configuration

    constructor(configuration: Configuration) {
        this.configuration = configuration;
    }

    async listModelsAsync(fetchOptions?: RequestOptions) {
        const {url, method, headers} = this.configuration.listModels();
        return requestApiAsync({url, method, headers}, fetchOptions);
    }

    async createChatCompletionAsync(options: CreateChatCompletionRequest, fetchOptions?: RequestOptions): Promise<ChatCompletionResponse> {
        const {url, method, headers} = this.configuration.createChatCompletion();
        return requestApiAsync({url, method, headers, body: options}, fetchOptions);
    }

    async createCompletionAsync(options: CompleteRequestBody, fetchOptions?: RequestOptions) {
        const {url, method, headers} = this.configuration.createCompletion();
        return requestApiAsync({url, method, headers, body: options}, fetchOptions);
    }

    async createImageAsync(options: CreateImageRequest, fetchOptions?: RequestOptions): Promise<ImagesResponse> {
        const {url, method, headers} = this.configuration.createImage();
        return requestApiAsync({url, method, headers, body: options}, fetchOptions);
    }

    async getCreditGrants(fetchOptions?: RequestOptions) {
        const {url, method, headers} = this.configuration.getCreditGrants();
        return requestApiAsync({url, method, headers}, fetchOptions);
    }

    async getBillingSubscription(fetchOptions?: RequestOptions) {
        const {url, method, headers} = this.configuration.getBillingSubscription();
        return requestApiAsync({url, method, headers}, fetchOptions);
    }

    async getBillingUsage(startDate: string, endDate: string, fetchOptions?: RequestOptions) {
        const {url, method, headers} = this.configuration.getBillingUsage(startDate, endDate);
        return requestApiAsync({url, method, headers}, fetchOptions);
    }

}

async function requestApiAsync(params: ApiRequestParams, fetchOptions: RequestOptions = {}) {
    const {url, method, headers, body} = params;
    const {headers: customHeaders, ...otherOptions} = fetchOptions;
    const response = await fetch(url, {
        method,
        headers: {
            ...headers,
            ...customHeaders,
        },
        body: body && JSON.stringify(body),
        ...otherOptions,
    });
    const data = await response.json();
    const {error} = data;
    if (error) {
        throw new ApiError(error);
    } else {
        return data;
    }
}