import AvatarContainer from "./AvatarContainer";
import {Text, StyleSheet} from "react-native";
import React from "react";

type Props = {
    size?: number,
}
const UserAvatar:React.FC<Props> = ({size}) => {
    return (
        <AvatarContainer backgroundColor={'#53AB68'} size={size}>
            <Text style={styles.text}>{'我'}</Text>
        </AvatarContainer>
    )
};

const styles = StyleSheet.create({
    text: {
        fontSize: 13,
        color: "#FFFFFF",
    },
});

export default UserAvatar;