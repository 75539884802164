import {Platform} from "react-native";

const nanoid = Platform.OS === 'web' ? require('nanoid').nanoid : require('nanoid/non-secure').nanoid;

function isWeChatBrowser() {
    // 当前环境不是浏览器环境时，直接返回 false
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
        return false;
    }

    const userAgent = window.navigator.userAgent;
    return /MicroMessenger/i.test(userAgent);
}

function generateId() {
    return nanoid();
}


export {
    isWeChatBrowser,
    generateId,
}