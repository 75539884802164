import React, {useState} from 'react';
import {useTheme} from "@react-navigation/native";
import {TextInput, StyleSheet, TextInputProps, Platform, TextStyle} from "react-native";

const PromptInput: React.FC<TextInputProps> = (props) => {
    const {colors, dark} = useTheme();
    const [textInputHeight, setTextInputHeight] = useState(58);
    return (
        <TextInput
            placeholder={'请输入图片描述内容'}
            placeholderTextColor={'#CCCCCC'}
            onContentSizeChange={event => {
                try {
                    const height = event?.nativeEvent?.contentSize?.height;
                    if (height && height >= 58) {
                        setTextInputHeight(height);
                    } else {
                        setTextInputHeight(58);
                    }
                } catch (e) {
                    setTextInputHeight(58);
                }
            }}
            style={[{
                minHeight: 58,
                maxHeight: 150,
                height: textInputHeight,
                color: colors.text,
                borderColor: colors.border,
                borderBottomWidth: StyleSheet.hairlineWidth,
                backgroundColor: colors.card,
                fontSize: 17,
                padding: 10,
                paddingTop: 16,
                paddingBottom: 16,
            },
                //消除web中获得焦点显示的边框
                Platform.OS === 'web' ? {outline: 'none'} as TextStyle : null
            ]}
            multiline={true}
            {...props}
        />
    )
};

export default PromptInput;