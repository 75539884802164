import {useTheme} from "@react-navigation/native";
import {View, StyleSheet, Text, ViewStyle} from "react-native";
import Button from "./Button";
import {Button as AlertButton} from './types';

type Props = {
    title: string,
    message: string,
    buttons: AlertButton[],
    style?: ViewStyle,
    closeAlert: () => void,
}

const AlertCard: React.FC<Props> = (props) => {
    const {title, message, buttons, style, closeAlert} = props;
    const {colors} = useTheme();
    const buttonComponents = [];
    for (let i = 0; i < buttons.length; i++) {
        const style = buttons.length <= 2 ? {flex: 1} : null;
        const {onPress, ...otherProps} = buttons[i];
        buttonComponents.push(
            <Button
                {...otherProps}
                key={`${i}`}
                right={buttons.length === 2 && i === 1}
                style={style}
                onPress={(e) => {
                    onPress && onPress(e);
                    closeAlert();
                }}
            />
        )
    }
    return (
        <View style={[styles.container, {
            backgroundColor: colors.background,
        }, style]}>
            <View style={styles.body}>
                <Text style={[styles.title, {color: colors.text}]}>{title}</Text>
                <Text style={[styles.message, {color: colors.text}]}>{message}</Text>
            </View>
            <View style={[styles.buttonsContainer, {
                flexDirection: buttons.length > 2 ? 'column' : 'row',
            }]}>
                {buttonComponents}
            </View>
        </View>
    )
};

const styles = StyleSheet.create({
    container: {
        width: 270,
        borderRadius: 10,
        overflow: 'hidden',
    },
    body: {
        minHeight: 80,
        alignItems: 'center',
        paddingTop: 22,
        padding: 12,
    },
    title: {
        fontSize: 17,
        fontWeight: 'bold',
        marginBottom: 4,
    },
    message: {
        fontSize: 13,
        textAlign: 'center',
    },
    buttonsContainer: {
        flexDirection: 'row',
    },
});

AlertCard.defaultProps = {
    buttons: [
        {text: 'OK'},
    ]
}

export default AlertCard;