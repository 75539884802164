import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Linking, View} from 'react-native';
import * as Clipboard from 'expo-clipboard';
import {MaterialIcons} from '@expo/vector-icons';
import Chat from "../../components/chat/Chat";
import Container from "../../components/Container";
import {updateSettingsAsync, useSettings} from "../../core/Settings";
import HeaderButton from "../../components/HeaderButton";
import {Alert, Toast} from "../../base";
import {useTheme} from "@react-navigation/native";
import QuickStart from "./components/QuickStart";
import {ApiError} from "../../openai";
import DailyCounter from "../../core/DailyCounter";
import {ChatSession} from "../../models";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import InputToolBar from "../../components/chat/InputToolBar";
import {Message} from "../../components/chat/types";
import checkEasterEgg from "./check-easter-egg";
import ChatClient from "../../openai/ChatClient";
import {generateId} from "../../core/utils";
import {ChatCompletionRequestMessage} from "../../openai/types";
import {Plugin} from "../../plugins/types";
import {getPlugin} from "../../plugins";
import {isTermsAgreed} from "../../core/ServiceTerms";
import {AppContext} from "../../components/AppContext";
import AppConstants from "../../constants/AppConstants";

const vkRequestCounter = new DailyCounter('@vkRequestCount');
type Props = NativeStackScreenProps<RootStackParamList, "Chat">;
const ChatScreen = ({navigation, route}: Props) => {
    const [sessionId, setSessionId] = useState<string | undefined>();
    const [messages, setMessages] = useState<Message[]>([]);
    const [generating, setGenerating] = useState(false);
    const settings = useSettings();
    const controllerRef = useRef<AbortController>();
    const inputToolBarRef = useRef<InputToolBar>(null);
    const theme = useTheme();
    const {loadSettings} = useContext(AppContext);

    const setFailedMessage = useCallback((messages: Message[], reason: string) => {
        const lastIndex = messages.length - 1;
        const lastMessage = messages[lastIndex];
        messages[lastIndex] = {...lastMessage, failed: true, failedReason: reason}
        setMessages([...messages]);
    }, []);

    const saveMessages = useCallback(async (messages: Message[]) => {
        if (sessionId) {
            await ChatSession.objects.updateAsync({id: sessionId}, {messages});
        } else {
            const session = new ChatSession({messages, title: messages[0].text});
            session.saveAsync().then(() => {
                setSessionId(session.id);
            })
        }
    }, [sessionId]);

    useEffect(() => {
        navigation.setOptions(({
            headerRight: ({tintColor}) => {
                return (
                    <View style={{
                        flexDirection: 'row',
                    }}>
                        {messages.length > 0 &&
                            <HeaderButton onPress={() => {
                                setSessionId('');
                                setMessages([]);
                                if (route?.params?.sessionId) {
                                    navigation.setParams({sessionId: undefined});
                                }
                            }}>
                                <MaterialIcons name="add" size={24} color={tintColor}/>
                            </HeaderButton>
                        }
                        <HeaderButton onPress={() => navigation.navigate('Settings')}>
                            <MaterialIcons name="settings" size={24} color={tintColor}/>
                        </HeaderButton>
                    </View>
                )
            },
            headerLeft: ({tintColor}) => {
                return (
                    <HeaderButton onPress={() => navigation.navigate('History', {sessionId})}>
                        <MaterialIcons name="chat-bubble-outline" size={24} color={tintColor}/>
                    </HeaderButton>
                )

            }
        }))
    }, [navigation, sessionId, messages])

    useEffect(() => {
        isTermsAgreed(AppConstants.SERVICE_TERMS_VERSION).then(result => {
            if (!result) {
                Alert.alert('您尚未同意服务条款', '只有同意服务条款后才可使用本应用', [
                    {
                        text: '确定',
                        onPress: () => {
                            updateSettingsAsync({apiKey: ''}).then(() => {
                                loadSettings();
                            })
                        }
                    }
                ])
            }
        })
    }, []);

    useEffect(() => {
        checkEasterEgg(navigation);
    }, []);

    useEffect(() => {
        const sessionId = route.params?.sessionId;
        if (sessionId) {
            ChatSession.objects.getAsync({id: sessionId}).then(session => {
                if (session) {
                    const {messages = [], id, title} = session;
                    setSessionId(id);
                    setMessages(messages);
                }
            })
        }
    }, [route?.params?.sessionId]);

    const changeSystemMessage = useCallback((message: Message | null) => {
        const [firstMessage, ...otherMessages] = messages;
        const systemMessageExist = firstMessage?.user === 'system';
        if (message) {
            if (systemMessageExist) {
                setMessages([message, ...otherMessages])
            } else {
                setMessages([message, ...messages])
            }
        } else {
            if (systemMessageExist) {
                setMessages([...otherMessages]);
            }
        }
    }, [messages]);

    useEffect(() => {
        const systemMessage = route.params?.systemMessage;
        if (systemMessage !== undefined) {
            changeSystemMessage(systemMessage);
        }
    }, [route.params?.systemMessage])

    const onSend = useCallback(async (message: Message) => {
        const {apiKey, apiBaseUrl, model, timeout, sessionMemory, plugins: pluginsStatus} = settings;
        const isVk = apiKey.startsWith('vk');
        let currentMessages: Message[] = [...messages, message, {user: 'bot', text: ''}];
        setGenerating(true);
        setMessages(currentMessages);
        const plugins: Array<Plugin> = [];
        for (let name of Object.keys(pluginsStatus)) {
            if (pluginsStatus[name]) {
                const plugin = getPlugin(name)
                if (plugin) {
                    plugins.push(plugin);
                }
            }
        }
        const client = new ChatClient({
            model: model,
            apiKey,
            baseUrl: apiBaseUrl,
            timeout,
        })
        const previousMessages: Message[] = [...messages, message];
        const systemMessage = previousMessages.length > 0 && previousMessages[0].user === 'system' ? previousMessages[0] : null;
        const submitMessages: Message[] = sessionMemory.enabled ? previousMessages : systemMessage ? [systemMessage, message] : [message];
        const newMessageId = generateId();
        try {
            navigation.setOptions({
                title: '正在连接服务器',
            })
            controllerRef.current = new AbortController();
            const result = await client.chat({
                messages: submitMessages.map(m => messageToChatCompletionRequestMessage(m)),
                plugins: plugins,
                onMessageUpdate: (message) => {
                    navigation.setOptions({
                        title: '正在答复',
                    })
                    const receivedMessage = {
                        id: newMessageId,
                        text: message.content as string,
                        user: 'bot',
                    }
                    currentMessages = [...previousMessages, receivedMessage];
                    setMessages(currentMessages);
                },
                beforeFunctionCall: (params) => {
                    const {pluginId} = params;
                    const plugin = getPlugin(pluginId) as Plugin;
                    currentMessages = [...previousMessages, {
                        id: newMessageId,
                        text: '',
                        usingPlugin: plugin.name,
                        user: 'bot',
                    }];
                    setMessages(currentMessages);
                },
                signal: controllerRef.current.signal,
            })
            if (isVk) {
                await vkRequestCounter.recordAsync();
            }
            setGenerating(false);
            //保存会话消息
            currentMessages = [...previousMessages, {
                id: newMessageId,
                text: result.content as string,
                user: 'bot',
            }];
            await saveMessages(currentMessages);
        } catch (err) {
            const error = err as Error;
            setGenerating(false);
            if (error.name === 'AbortError') {
                setFailedMessage(currentMessages, '你已取消请求');
                if (isVk) {
                    await vkRequestCounter.recordAsync();
                }
            } else if (error.name === 'TimeoutError') {
                const errorMessage = '网络请求超时，请检查您的网络后重试';
                Alert.alert('请求超时', errorMessage, [
                    {text: '详情', onPress: () => navigation.navigate('ErrorDetail', {code: 12000})},
                    {text: '确定'},
                ]);
                setFailedMessage(currentMessages, errorMessage);
            } else if (error.message === 'Failed to fetch') {
                const errorMessage = '无法连接到服务器，请检查你的网络环境后重试';
                Alert.alert('连接失败', errorMessage, [
                    {text: '详情', onPress: () => navigation.navigate('ErrorDetail', {code: 12001})},
                    {text: '确定'},
                ]);
                setFailedMessage(currentMessages, errorMessage);
            } else if (error instanceof ApiError) {
                if (error.type === ApiError.TYPE.INSUFFICIENT_QUOTA) {
                    Alert.alert('API Error', '账号余额不足或已过期，推案使用ChatGPT国内直连', [
                        {text: '取消'},
                        {
                            text: '解决方案', onPress: () => {
                                Linking.openURL('https://mp.weixin.qq.com/s/KMzY89WimQZRXhV7ux4Y0Q')
                            }
                        }
                    ])
                    setFailedMessage(currentMessages, '当前API密钥所属账号余额不足或超出配额，请查看您的订阅计划和账单明细。推荐您使用ChatGPT国内直连，只需访问https://gpt.feng.to');
                    return;
                }
                if (error.message === 'Unrecognized request argument supplied: functions') {
                    setFailedMessage(currentMessages, `你选择模型"${model}"不支持插件功能，请关闭插件或选择支持插件的模型。`);
                    return;
                }
                switch (error.code) {
                    case ApiError.CODE.INVALID_API_KEY:
                        Alert.alert('API Error', '当前API密钥已失效，请重新设置', [
                            {text: '取消'},
                            {text: '前往设置', onPress: () => navigation.navigate('SetApiKey')}
                        ])
                        setFailedMessage(currentMessages, '当前API密钥已失效，请重新设置');
                        break;
                    case ApiError.CODE.CONTEXT_LENGTH_EXCEEDED:
                        Alert.alert('API Error', '前会话内容已经超出模型支持的最大限制，请清空会话或新建会话', [
                            {text: '取消'},
                            {text: '前往设置', onPress: () => navigation.navigate('SetApiKey')}
                        ])
                        setFailedMessage(currentMessages, '当前会话内容已经超出模型支持的最大限制，请清空会话或新建会话');
                        break;
                    default:
                        Alert.alert('API Error', error.message, [
                            {
                                text: '复制',
                                onPress: () => {
                                    Clipboard.setStringAsync(JSON.stringify(error.getData())).then(() => {
                                        Toast.show({text: '已复制'});
                                    })
                                }
                            },
                            {text: '确定'}
                        ]);
                        setFailedMessage(currentMessages, error.message);
                }
            } else {
                console.error(error);
                setFailedMessage(currentMessages, error.message);
            }
        } finally {
            navigation.setOptions({
                title: 'ChatGPT客户端',
            })
        }
    }, [navigation, settings, messages, sessionId]);

    const onPressStop = useCallback(() => {
        controllerRef.current?.abort();
    }, []);

    const onPressClean = useCallback(() => {
        Alert.alert('清空会话', '确定清空当前会话内容吗？', [
            {text: '取消'},
            {
                text: '确定', onPress: async () => {
                    if (sessionId) {
                        await ChatSession.objects.deleteAsync({id: sessionId})
                    }
                    setMessages([]);
                    setSessionId(undefined);
                }
            },
        ])
    }, [sessionId]);

    return (
        <Container>
            {messages.length > 0 ?
                <Chat messages={messages}/> :
                <View style={{
                    flex: 1,
                }}>
                    <QuickStart onPressExample={(text) => inputToolBarRef.current?.setInputText(text)}/>
                </View>
            }
            <InputToolBar
                theme={theme}
                onSend={onSend}
                ref={inputToolBarRef}
                generating={generating}
                onPressStop={onPressStop}
                onPressClean={onPressClean}
                onPressSystemMessage={() => {
                    const [message] = messages;
                    if (message?.user == 'system') {
                        navigation.navigate('SetSystemMessage', {content: message.text});
                    } else {
                        navigation.navigate('SetSystemMessage');
                    }
                }}
            />
        </Container>
    )
}

function messageToChatCompletionRequestMessage(message: Message): ChatCompletionRequestMessage {
    let role = null;
    if (message.user == 'bot' || message.user == 'assistant') {
        return {
            role: 'assistant',
            content: message.text,
        }
    } else if (message.user == 'system') {
        return {
            role: 'system',
            content: message.text,
        }
    } else {
        return {
            role: 'user',
            content: message.text,
        }
    }
}

export default ChatScreen;