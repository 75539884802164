import {useTheme} from "@react-navigation/native";
import {View, StyleSheet, TextInput} from "react-native";
import PropTypes from "prop-types";
import React from "react";

const ListItemInput = (props) => {
    const {colors, dark} = useTheme();
    const {style, ...otherProps} = props;
    return (
        <View
            style={{
                backgroundColor: colors.card,
                minHeight: 58,
                paddingLeft: 12,
                paddingRight: 12,
                flexDirection: 'row',
                borderColor: colors.border,
                borderBottomWidth: StyleSheet.hairlineWidth,
            }}>
            <TextInput
                {...otherProps}
                clearButtonMode={"while-editing"}
                placeholderTextColor={dark ? '#888888' : '#B3B3B3'}
                style={[{
                    color: colors.text,
                    flex: 1,
                    outline: 'none',
                    fontSize: 16,
                }, style]}
            />
        </View>
    )
};

ListItemInput.propTypes = {
    value: PropTypes.string,
    onChangeText: PropTypes.func,
    autoFocus: PropTypes.bool,
    placeholder:PropTypes.string,
};

export default ListItemInput;