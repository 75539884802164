import * as React from 'react';

export const actionSheetRef = React.createRef();

export function show({options}) {
    actionSheetRef.current?.show({options});
}

export default {
    show,
}