import {ChatCompletionFunction} from './types';
import {Plugin} from '../plugins/types';
import {pull} from "lodash-es";

type Func = (params?: object) => unknown
export default class PluginManager {

    functions: {
        [key: string]: {
            func: Func,
            meta: ChatCompletionFunction,
            pluginId: string,
        }
    } = {}

    addPlugin(plugin: Plugin) {
        const {id: pluginId} = plugin;
        for (let pluginFunction of plugin.functions) {
            const {func, meta} = pluginFunction;
            const functionName = meta.name;
            this.functions[functionName] = {
                func,
                meta,
                pluginId,
            }
        }
    }

    removePlugin(pluginId: string) {
        for (let [key, value] of Object.entries(this.functions)) {
            if (pluginId === value.pluginId) {
                delete this.functions[key];
                break;
            }
        }
    }

    getFunctions(): Array<ChatCompletionFunction> | undefined {
        const result = Object.values(this.functions).map(({meta}) => meta);
        return result.length > 0 ? result : undefined;
    }

    async callFunction(functionName: string, parameters: string): Promise<string> {
        const {func} = this.functions[functionName]
        const result = func(JSON.parse(parameters))
        if (result instanceof Promise) {
            return JSON.stringify(await result);
        } else {
            return JSON.stringify(result)
        }
    }
}