import {Plugin} from "./types";

type Params = {
    city: string,
    extensions?: 'base' | 'all',
};

const WeatherPlugin: Plugin = {
    id: 'weather',
    name: '天气查询',
    description: '"天气查询"插件允许AI助理查询国内天气情况。',
    functions: [
        {
            func: (params: Params) => {
                const {city, extensions = 'base'} = params;
                const url = `https://wx.feng.to/api/v1/weather/?city=${city}&extensions=${extensions}`;
                return fetch(url).then(response => response.json());
            },
            meta: {
                name: 'get_china_weather',
                description: 'Get current weather and forecast weather for Chinese cities',
                parameters: {
                    type: 'object',
                    properties: {
                        city: {
                            type: 'string',
                            description: 'The adcode of the city in China for which the weather is queried. For example, setting "110000" means querying the weather in Beijing'
                        },
                        extensions: {
                            type: 'string',
                            description: '"base" means only get the current weather, "all" will get the current weather and forecast weather',
                            enum: ['base', 'all']
                        }
                    },
                    required: ['city']
                }
            }
        },
    ]
}

export default WeatherPlugin;