import React, {useEffect, useState} from "react";
import {ScrollView, StyleSheet, Keyboard} from "react-native";
import Container from "../../components/Container";
import DoneButton from "../../components/DoneButton";
import PromptInput from "./components/PromptInput";
import {useSettings} from "../../core/Settings";
import {Client, Configuration} from '../../openai';
import Loading from "../../components/Loading";
import {Alert} from "../../base";
import {ListItemGroupTitle} from "../../components/ListItem";
import CheckBoxListItem from "../../components/CheckBoxListItem";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "../types";
import {ImageSize} from "../../openai/types";

type Props = NativeStackScreenProps<RootStackParamList, "Draw">;

const DrawScreen = ({navigation}: Props) => {
    const [prompt, setPrompt] = useState('');
    const [size, setSize] = useState<ImageSize>('512x512');
    const [loading, setLoading] = useState(false);
    const settings = useSettings();
    useEffect(() => {
        const {apiKey, apiBaseUrl: baseUrl} = settings;
        const disabled = loading || prompt.trim().length === 0;
        navigation.setOptions({
            headerRight: () => {
                return (
                    <DoneButton
                        title={'生成'}
                        disabled={disabled}
                        onPress={() => {
                            Keyboard.dismiss();
                            if (apiKey.startsWith('vk')) {
                                Alert.alert('请求不支持', '已"vk"开头的虚拟API密钥不支持绘图功能，请设置自己的API密钥')
                                return;
                            }
                            setLoading(true);
                            const configuration = new Configuration({baseUrl, apiKey});
                            const client = new Client(configuration);
                            client.createImageAsync({
                                prompt,
                                size,
                                response_format: 'url',
                            }).then(data => {
                                setLoading(false)
                                const {data: [{url, b64_json: base64}]} = data;
                                navigation.navigate('DrawResult', {url, base64, prompt});
                            }).catch(error => {
                                setLoading(false)
                                Alert.alert(error.name, error.message);
                            })
                        }}/>
                )
            }
        })
    }, [navigation, prompt, size, settings, loading])

    return (
        <Container>
            <ScrollView contentContainerStyle={{
                paddingBottom: 12,
            }}>
                <PromptInput
                    placeholder={'请输入描述内容，AI将根据该内容生成图片'}
                    autoFocus
                    value={prompt}
                    onChangeText={setPrompt}/>
                <ListItemGroupTitle>选择图片尺寸</ListItemGroupTitle>
                <CheckBoxListItem title={'256 × 256'} checked={size === '256x256'} onPress={() => setSize('256x256')}/>
                <CheckBoxListItem title={'512 × 512'} checked={size === '512x512'} onPress={() => setSize('512x512')}/>
                <CheckBoxListItem title={'1024 × 1024'} checked={size === '1024x1024'}
                                  onPress={() => setSize('1024x1024')}/>
            </ScrollView>
            {loading && <Loading/>}
        </Container>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    input: {
        // flexGrow: 1,
        width: '100%',
        // height: 'auto',
        maxHeight: 200,
        minHeight: 58,
        borderColor: 'gray',
        borderWidth: 1,
        padding: 10,
    },
});
export default DrawScreen;