import Container from "../components/Container";
import {Linking, ScrollView} from "react-native";
import ListItem, {ListItemGroupTitle} from "../components/ListItem";
import React from "react";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "Help">;
const HelpScreen = ({navigation}: Props) => {
    return (
        <Container>
            <ScrollView>
                <ListItemGroupTitle>反馈</ListItemGroupTitle>
                <ListItem
                    chevron
                    title={'微信公众号'}
                    onPress={() => navigation.navigate('WeChatOfficialAccount')}/>
                <ListItem
                    chevron
                    title={'加入微信群'}
                    onPress={() => navigation.navigate('JoinWeChatGroup')}/>
                <ListItem
                    chevron
                    title={'联系开发者'}
                    onPress={() => navigation.navigate('ContactDeveloper')}/>
                <ListItemGroupTitle>常见问题</ListItemGroupTitle>
                <ListItem
                    chevron
                    title={'如何创建API密钥'}
                    onPress={() => Linking.openURL("https://blog.feng.to/2023/04/08/how-to-create-openai-api-key/")}/>
                <ListItem
                    chevron
                    title={'请求超时解决办法'}
                    onPress={() => Linking.openURL("https://blog.feng.to/2023/04/09/chatgpt-client-request-timeout-solutions/")}/>
                <ListItem
                    chevron
                    title={'国内免网络代理设置'}
                    onPress={() => Linking.openURL("https://blog.feng.to/2023/04/07/chatgpt-client-changes-api-server/")}/>
                <ListItem
                    chevron
                    title={'查看博客获取更多帮助'}
                    onPress={() => Linking.openURL("https://blog.feng.to")}/>
            </ScrollView>
        </Container>
    )
}

export default HelpScreen;