import React, {useState} from 'react';
import {Text, StyleSheet, Pressable, View, ViewStyle} from "react-native";
import {useTheme} from "@react-navigation/native";
import {FontAwesome} from '@expo/vector-icons';

type Props = {
    onPress?: (text: string) => void,
    text: string,
    style?: ViewStyle
}

const Example: React.FC<Props> = (props) => {
    const {text, onPress, style} = props;
    const [pressIn, setPressIn] = useState(false);
    const {dark, colors} = useTheme();
    const containerColors = dark ? darkColors : lightColors;
    return (
        <Pressable
            onPressIn={() => setPressIn(true)}
            onPressOut={() => setPressIn(false)}
            onPress={() => {
                onPress && onPress(text);
            }}
            style={[
                styles.container,
                {backgroundColor: pressIn ? containerColors.pressInBackground : containerColors.background},
                style,
            ]}>
            <View style={{
                flex: 1,
            }}>
                <Text style={{
                    color: colors.text,
                    fontSize: 15,
                }}>"{text}"</Text>
            </View>
            <FontAwesome name="angle-right" size={24} color={colors.text}/>
        </Pressable>
    )
};

const lightColors = {
    pressInBackground: '#D9D9E2',
    background: '#F7F7F8',
}
const darkColors = {
    pressInBackground: '#202123',
    background: '#3E3F4A',
}

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        borderRadius: 8,
        paddingTop: 10,
        paddingRight: 10,
        paddingLeft: 10,
        paddingBottom: 10,
        marginTop: 10,
        marginRight: 16,
        marginLeft: 16,
    }
})

export default Example;