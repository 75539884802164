import Text from "../../../components/Text";
import {ScrollView, View, StyleSheet} from "react-native";
import {Fontisto} from "@expo/vector-icons";
import Example from "./Example";
import React from "react";
import {useTheme} from "@react-navigation/native";

type Props = {
    onPressExample: (text: string) => void;
}

const QuickStart:React.FC<Props> = (props) => {
    const {onPressExample} = props;
    const {colors} = useTheme();
    return (
        <ScrollView style={styles.container}>
            <Text style={styles.title}>开始和AI聊天吧</Text>
            <View style={styles.contentContainer}>
                <View style={styles.h2Container}>
                    <Fontisto name="day-sunny" size={20} color={colors.text}/>
                    <Text style={styles.h2}>样例</Text>
                </View>
                <Example onPress={onPressExample} text={'用简单的术语解释量子计算'}/>
                <Example onPress={onPressExample} text={'如何制作麻婆豆腐'}/>
                <Example onPress={onPressExample} text={'写一篇辞职信'}/>
                <Example onPress={onPressExample} text={'写一首歌颂爱情的诗歌'}/>
                <Example onPress={onPressExample} text={'将"你有空吗"翻译成英文'}/>
                <Example onPress={onPressExample} text={'使用python编写快速排序'}/>
                <Example onPress={onPressExample} text={'你觉得你会取代人类吗？'}/>
            </View>
        </ScrollView>
    )
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    title: {
        marginTop: 20,
        fontSize: 20,
        alignSelf: 'center',
    },
    contentContainer: {
        alignItems: 'center',
        paddingTop: 15,
        paddingRight: 12,
        paddingLeft: 12,
    },
    h2Container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    h2: {
        fontWeight: 'bold',
        marginLeft: 8,
    }

});

export default QuickStart;