import Container from "../components/Container";
import {View} from "react-native";
import QRCode from "../components/QRCode";
import AppConstants from "../constants/AppConstants";
import Text from "../components/Text";
import Env from "../constants/Env";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";

type Props = NativeStackScreenProps<RootStackParamList, "ErrorDetail">;
const ErrorDetailScreen = ({route}: Props) => {
    const {params: {code} = {}} = route;
    const {error, description} = getError(code);
    return (
        <Container padding>
            <Text>代码：{code}</Text>
            <Text>名称：{error}</Text>
            <Text>描述：{description}</Text>
            <View style={{
                marginTop: 20,
                alignItems: 'center',
            }}>
                <Text>公众号回复"<Text style={{fontWeight: 'bold'}}>{code}</Text>"获取该问题解决方法</Text>
                <View style={{
                    marginTop: 10,
                    marginBottom: 10,
                }}>
                    <QRCode
                        size={200}
                        value={AppConstants.WECHAT_OFFICIAL_ACCOUNT_QR}
                    />
                </View>
                <Text style={{
                    fontSize: 17,
                }}>{TITLE}</Text>
            </View>
        </Container>
    )
}

const TITLE = Env.WECHAT_BROWSER ? '长按二维码前往公众号' : '微信扫二维码前往公众号';

type Error = {
    error: string,
    description: string,
}

const ERRORS: { [key: number]: Error } = {
    12000: {
        error: '请求超时',
        description: '向服务器发出请求时，长时间未获得服务器响应则会提示该错误。一般是由于你的网络环境导致。',
    },
    12001: {
        error: '连接服务器失败',
        description: '无法连接到服务器，一般是由于的你的网络环境导致。',
    }
}

function getError(code: number | undefined) {
    if (code !== undefined) {
        return ERRORS[code]
    } else {
        return {error: '未知', description: ''};
    }
}

export default ErrorDetailScreen;