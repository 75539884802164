import RotatingImage from "../../../components/RotatingImage";
import React from "react";
import {ViewStyle} from "react-native";
import {GestureResponderEvent} from "react-native/Libraries/Types/CoreEventTypes";

type Props = {
    style?: ViewStyle,
    onPress?: ((event: GestureResponderEvent) => void) | undefined,
    size?: number,
}
const Star: React.FC<Props> = (props) => {
    const {onPress, style, size} = props;
    return (
        <RotatingImage
            source={require('../../../../assets/images/birthday2023_star.png')}
            onPress={onPress}
            size={size}
            style={style}
        />
    )
}

Star.defaultProps = {
    size: 40,
}

export default Star;