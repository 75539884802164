import React from 'react';
import {View, Text, ColorValue, ViewStyle} from "react-native";

type Props = {
    type: 'primary' | 'secondary' | 'info' | 'success' | 'warning' | 'danger' | 'light' | 'dark',
    children: string,
    style?: ViewStyle,
}
const Badge: React.FC<Props> = (props) => {
    const {type, children, style} = props;
    const colors = badgeColors[type];
    return (
        <View style={[{
            backgroundColor: colors.backgroundColor,
            borderRadius: 5,
            paddingRight: 5,
            paddingLeft: 5,
            paddingTop: 2,
            paddingBottom: 2,
        }, style]}>
            <Text style={{
                color: colors.fontColor,
                fontSize: 9,
            }}>{children}</Text>
        </View>
    );
}

type ColorInfo = {
    backgroundColor: ColorValue
    fontColor: ColorValue,
}

const badgeColors: { [key: string]: ColorInfo } = {
    'primary': {
        backgroundColor: '#007bff',
        fontColor: '#FFFFFF',
    },
    'secondary': {
        backgroundColor: '#6c757d',
        fontColor: '#FFFFFF',
    },
    'info': {
        backgroundColor: '#17a2b8',
        fontColor: '#FFFFFF',
    },
    'success': {
        backgroundColor: '#28a745',
        fontColor: '#FFFFFF',
    },
    'warning': {
        backgroundColor: '#ffc107',
        fontColor: '#212529',
    },
    'danger': {
        backgroundColor: '#dc3545',
        fontColor: '#FFFFFF',
    },
    'light': {
        backgroundColor: '#f8f9fa',
        fontColor: '#212529',
    },
    'dark': {
        backgroundColor: '#343a40',
        fontColor: '#FFFFFF',
    },
};

Badge.defaultProps = {
    type: 'primary',
};

export default Badge