import React, {useCallback} from 'react';
import {ScrollView, useColorScheme, Switch, View, Text} from 'react-native';
import Constants from 'expo-constants';
import Container from "../components/Container";
import ListItem, {ListItemGroupTitle} from "../components/ListItem";
import {useSettings, getApiServerByUrl, updateSettingsAsync} from "../core/Settings";
import {Configuration, Client} from "../openai";
import {Alert} from "../base";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";
import MultiClickTouchable from "../components/MultiClickTouchable";
import {useConfiguration} from "../core/Configuration";

type Props = NativeStackScreenProps<RootStackParamList, "Settings">;
const SettingsScreen = ({navigation}: Props) => {
    const {apiKey, theme: userTheme, model, apiBaseUrl, sessionMemory} = useSettings();
    const colorScheme = useColorScheme();
    const configuration = useConfiguration();
    const theme = userTheme === 'auto' && colorScheme === null ? 'light' : userTheme;
    useCallback(() => {
        const baseUrl = apiBaseUrl;
        const configuration = new Configuration({
            apiKey,
            baseUrl,
        });
        const client = new Client(configuration);
        client.getCreditGrants().then(data => {
            Alert.alert('', JSON.stringify(data))
        }).catch(e => () => {
            Alert.alert(e.name, e.message);
        })
    }, [apiBaseUrl, apiKey]);

    return (
        <Container>
            <ScrollView>
                <ListItem
                    chevron
                    title={'API密钥'}
                    note={apiKey ? '已设置' : '未设置'}
                    onPress={() => navigation.navigate('SetApiKey')}/>
                <ListItem
                    chevron
                    title={'主题'}
                    note={theme === 'light' ? '普通' : theme === 'dark' ? '深色' : '跟随系统'}
                    onPress={() => navigation.navigate('Theme')}
                />
                <ListItem
                    chevron
                    title={'模型'}
                    note={model}
                    onPress={() => navigation.navigate('SetModel')}
                />
                <ListItem
                    chevron
                    title={'API服务器'}
                    note={apiBaseUrl && getApiServerByUrl(apiBaseUrl)?.name}
                    onPress={() => navigation.navigate('SelectApiServer')}
                />
                <ListItemGroupTitle>功能</ListItemGroupTitle>
                <ListItem
                    chevron
                    title={'会话记忆'}
                    rightComponent={() => {
                        return (
                            <View style={{justifyContent: 'center'}}>
                                <Switch value={sessionMemory.enabled}
                                        onValueChange={value => {
                                            updateSettingsAsync({sessionMemory: {...sessionMemory, enabled: value}})
                                        }}/>
                            </View>)
                    }}
                />
                <ListItem
                    chevron
                    title={'插件'}
                    onPress={() => {
                        if (configuration.unlockPlugin) {
                            navigation.navigate('Plugin');
                        } else {
                            Alert.alert('功能未解锁', '插件功能目前处于测试阶段，如需体验，请前往公众号回复"CJ"', [
                                {text: '取消'},
                                {
                                    text: "查看公众号",
                                    onPress: () => navigation.navigate('WeChatOfficialAccount', {message: '公众号回复"CJ"解锁插件功能'})
                                }
                            ])
                        }
                    }}
                />
                <ListItem
                    style={{marginTop: 10}}
                    chevron
                    title={'账单和用量'}
                    onPress={() => navigation.navigate('OpenAiBalance')}
                />
                <ListItem
                    chevron
                    title={'帮助与反馈'}
                    onPress={() => navigation.navigate('Help')}
                />
                <MultiClickTouchable clicksNeeded={5} onPress={() => navigation.navigate('FeatureUnlock')}>
                    <Text selectable={false} style={{
                        fontSize: 13,
                        marginTop: 20,
                        color: '#888888',
                        alignSelf: 'center',
                    }}>{`${Constants.expoConfig?.name} ${Constants.expoConfig?.version}`}</Text>
                </MultiClickTouchable>
            </ScrollView>
        </Container>
    )
};

export default SettingsScreen;