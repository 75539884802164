export interface ApiErrorData {
    message: string,
    type: string,
    code?: string | null,
    param?: any,
}

export default class ApiError extends Error {

    type: string;
    code?: string | null;
    param?: any;
    name: string = 'ApiError';

    constructor(data: ApiErrorData) {
        const {message, type, code, param} = data;
        super(message);
        this.type = type;
        this.code = code;
        this.param = param;
    }

    getData = () => {
        return {
            message: this.message,
            type: this.type,
            code: this.code,
            param: this.param,
        }
    }

    static CODE = {
        INVALID_API_KEY: 'invalid_api_key',
        CONTEXT_LENGTH_EXCEEDED: 'context_length_exceeded',
    }
    static TYPE = {
        INSUFFICIENT_QUOTA: 'insufficient_quota',
    }
}