import {TouchableOpacity, Text, StyleSheet, ViewStyle} from "react-native";
import {useTheme} from "@react-navigation/native";
import {GestureResponderEvent} from "react-native/Libraries/Types/CoreEventTypes";

type Props = {
    text: string,
    onPress?: ((event: GestureResponderEvent) => void) | undefined,
    right?: boolean,
    style?: ViewStyle | null,
}

const Button:React.FC<Props> = (props) => {
    const {text, onPress, right, style} = props;
    const {colors} = useTheme();
    return (
        <TouchableOpacity
            onPress={onPress}
            style={[{
                height: 44,
                justifyContent: 'center',
                alignItems: 'center',
                borderTopWidth: StyleSheet.hairlineWidth,
                borderColor: colors.border,
                borderLeftWidth: right ? StyleSheet.hairlineWidth : 0,
            }, style]}>
            <Text style={{
                fontSize: 17,
                fontWeight: 'bold',
                color: colors.text,
            }}>{text}</Text>
        </TouchableOpacity>
    )
}

export default Button;