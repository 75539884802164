import React from "react";
import {TouchableOpacity, Text} from "react-native";
import {useNavigation} from "@react-navigation/native";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {RootStackParamList} from "../screens/types";

const NavFreeApiKeyButton = () => {
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    return (
        <TouchableOpacity onPress={() => navigation.navigate('GetApiKey')}>
            <Text style={{color: '#6FC3E4', fontSize: 15}}>免费获取API密钥</Text>
        </TouchableOpacity>
    )
}

export default NavFreeApiKeyButton;