import AsyncStorage from '@react-native-async-storage/async-storage';

export default class DailyCounter {
    constructor(key) {
        this.key = key;
    }

    getAsync = async () => {
        const {date, count = 0} = JSON.parse(await AsyncStorage.getItem(this.key) || '{}');
        const currentDate = getCurrentDate();
        if (date === currentDate) {
            return count;
        } else {
            return 0;
        }
    };

    recordAsync = async () => {
        const count = await this.getAsync();
        await AsyncStorage.setItem(this.key, JSON.stringify({
            date: getCurrentDate(),
            count: count + 1,
        }))
        return count + 1;
    };

    clearAsync = async () => {
        return AsyncStorage.removeItem(this.key);
    };
}

function getCurrentDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}