import React from "react";
import {View, Text, TouchableOpacity} from "react-native";
import {useNavigation, useTheme} from "@react-navigation/native";
import {MaterialCommunityIcons, MaterialIcons} from "@expo/vector-icons";
import QuickSettingsButton from "./QuickSettingsButton";
import {updateSettingsAsync, useSettings} from "../../core/Settings";
import {Alert, Toast} from "../../base";
import {NativeStackNavigationProp} from "@react-navigation/native-stack";
import {RootStackParamList} from "../../screens/types";
import {useConfiguration} from "../../core/Configuration";

type Props = {
    onPressClean?: () => void;
    onPressSystemMessage?: () => void;
}

const QuickSettingsBar: React.FC<Props> = (props) => {
    const {onPressClean, onPressSystemMessage} = props;
    const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();
    const {colors} = useTheme();
    const {sessionMemory} = useSettings();
    const configuration = useConfiguration();
    return (
        <View style={{
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: 40,
            width: '100%',
            backgroundColor: colors.background,
            paddingRight: 12,
            paddingLeft: 12,
        }}>
            <View style={{flexDirection: 'row'}}>
                <QuickSettingsButton onPress={onPressClean}>
                    <MaterialCommunityIcons name="silverware-clean" size={24} color={colors.text}/>
                </QuickSettingsButton>
                <QuickSettingsButton onPress={() => {
                    const enabled = !sessionMemory.enabled;
                    updateSettingsAsync({sessionMemory: {...sessionMemory, enabled}}).then(({sessionMemory}) => {
                        if (sessionMemory.enabled) {
                            Toast.show({text: '已开启会话记忆', position: 'top'});
                        } else {
                            Toast.show({text: '已关闭会话记忆', position: 'top'});
                        }
                    })
                }}>
                    <MaterialIcons name="history" size={24} color={sessionMemory.enabled ? '#1E90FF' : colors.text}/>
                </QuickSettingsButton>
                <QuickSettingsButton onPress={() => {
                    if (configuration.unlockPlugin) {
                        navigation.navigate('Plugin');
                    } else {
                        Alert.alert('功能未解锁', '插件功能目前处于测试阶段，如需体验，请前往公众号回复"CJ"', [
                            {text: '取消'},
                            {
                                text: "查看公众号",
                                onPress: () => navigation.navigate('WeChatOfficialAccount', {message: '公众号回复"CJ"解锁插件功能'})
                            }
                        ])
                    }
                }}>
                    <MaterialIcons name="extension" size={24} color={colors.text}/>
                </QuickSettingsButton>
                <QuickSettingsButton onPress={() => navigation.navigate('Draw')}>
                    <MaterialCommunityIcons name="draw" size={24} color={colors.text}/>
                </QuickSettingsButton>
            </View>
            <View style={{flexDirection: 'row'}}>
                <TouchableOpacity onPress={onPressSystemMessage}>
                    <Text style={{
                        fontSize: 12,
                        color: colors.text,
                    }}>系统消息</Text>
                </TouchableOpacity>
            </View>

        </View>
    )
}

export default QuickSettingsBar;