import React from 'react';
import {Image} from 'react-native';
import SvgQRCode from 'react-native-qrcode-svg';
import Env from "../constants/Env";

type Props = {
    size?: number,
    value: string,
    type?: 'image' | 'svg',
}

const QRCode: React.FC<Props> = (props) => {
    const {size, value, type} = props;
    if (type === 'svg') {
        return <SvgQRCode size={size} value={value}/>;
    } else {
        const uri = `https://api.qrserver.com/v1/create-qr-code/?size=${size}x${size}&data=${value}`;
        return <Image source={{uri: uri}} style={{width: size, height: size}}/>;
    }
}

QRCode.defaultProps = {
    size: 200,
    type: Env.WECHAT_BROWSER ? 'image' : 'svg',
}

export default QRCode;