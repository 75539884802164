import {useTheme} from "@react-navigation/native";
import {Text, Linking} from 'react-native';
import * as PropTypes from 'prop-types';

const Link = (props) => {
    const {children, url, style, navigation, screen, ...otherProps} = props;
    const {dark} = useTheme();
    return (
        <Text
            style={[{
                color: dark ? '#7D92A8' : '#546A99',
                fontSize: 17,
            }, style]}
            onPress={() => {
                if (url) {
                    Linking.openURL(url);
                }
                if (navigation && screen) {
                    navigation.navigate(screen);
                }
            }}
            {...otherProps}
        >{children}</Text>
    )
}

Link.propTypes = {
    url: PropTypes.string,
    screen: PropTypes.string,
    navigation: PropTypes.shape({
        navigate: PropTypes.func,
    }),
    onPress: PropTypes.func,
    style: PropTypes.oneOfType([PropTypes.number, PropTypes.array, PropTypes.object]),
    children: PropTypes.any,
}

export default Link;