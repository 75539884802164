import {useTheme} from "@react-navigation/native";
import {Pressable, StyleSheet, Text, View} from "react-native";
import {FontAwesome5} from "@expo/vector-icons";
import StatusIcon, {Status} from "./StatusIcon";
import {useEffect, useState} from "react";
import {fetchWithTimeout as fetch} from "../../../openai";
import {ApiServer} from "../../../core/types";

interface ApiServerListItemProps {
    checked?: boolean;
    apiServer: ApiServer;
    onPress?: () => void;
}

const ApiServerListItem:React.FC<ApiServerListItemProps> = (props) => {
    const {apiServer, checked, onPress} = props;
    const [status, setStatus] = useState<Status>('pending');
    const [delayTime, setDelayTime] = useState(0);
    const {name, baseUrl} = apiServer;
    const {colors} = useTheme();
    useEffect(() => {
        const startTime = new Date().getTime();
        checkServerConnectionIsOk(baseUrl).then(result => {
            const time = new Date().getTime() - startTime;
            if (result) {
                setStatus('ok');
                setDelayTime(time);
            } else {
                setStatus('error');
            }
        })
    }, []);
    return (
        <Pressable
            onPress={onPress}
            style={{
                backgroundColor: colors.card,
                minHeight: 58,
                paddingLeft: 12,
                paddingRight: 12,
                flexDirection: 'row',
                borderColor: colors.border,
                borderBottomWidth: StyleSheet.hairlineWidth,
            }}>
            <View style={{
                flex: 1,
                flexDirection: 'row',
                alignItems: 'center',
            }}>
                <StatusIcon status={status}/>
                <Text style={{
                    fontSize: 17,
                    color: colors.text,
                    marginLeft: 8,
                }}>{name}</Text>
                {delayTime > 0 &&
                    <Text style={{
                        fontSize: 13,
                        marginLeft: 8,
                        color: delayTime < 500 ? "#77B756" : "#F8C84E",
                    }}>{delayTime}ms</Text>
                }
            </View>
            <View style={{
                justifyContent: 'center',
            }}>
                {checked && <FontAwesome5 name="check" size={18} color={'#08A94F'}/>}
            </View>
        </Pressable>
    )
};

async function checkServerConnectionIsOk(baseUrl: string) {
    const url = `${baseUrl}/v1/models`;
    try {
        await fetch(url, {timeout: 10000});
        return true;
    } catch (error) {
        return false;
    }
}

export default ApiServerListItem;