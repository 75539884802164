import React, {useEffect, useState} from 'react';
import {Keyboard, ScrollView} from 'react-native';
import Container from "../components/Container";
import ListItemInput from "../components/ListItemInput";
import DoneButton from "../components/DoneButton";
import Loading from "../components/Loading";
import {Alert, Toast} from "../base";
import {NativeStackScreenProps} from "@react-navigation/native-stack";
import {RootStackParamList} from "./types";
import {updateConfigurationAsync} from "../core/Configuration";

type Props = NativeStackScreenProps<RootStackParamList, "FeatureUnlock">;
const FeatureUnlockScreen: React.FC<Props> = ({navigation}) => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const apiKey = text.trim();
        const disabled = apiKey.length === 0;
        navigation.setOptions({
            headerRight: (headerTint) => {
                return (
                    <DoneButton
                        disabled={disabled}
                        onPress={async () => {
                            setLoading(true);
                            Keyboard.dismiss();
                            setTimeout(() => {
                                setLoading(false);
                                const value = passwordMaps[text];
                                switch (value) {
                                    case 'navBirthday2023':
                                        navigation.navigate('HappyBirthday20230522');
                                        break;
                                    case 'unlockPlugin':
                                        updateConfigurationAsync({unlockPlugin: true}).then(() => {
                                            Toast.show({text: '已解锁插件功能', position: 'top', type: 'success'});
                                            navigation.goBack();
                                        });
                                        break;
                                    default:
                                        Alert.alert('口令错误', '输入口令错误, 请重新尝试')
                                }
                            }, 1000);
                        }}/>
                )
            }
        });
    }, [text])

    return (
        <Container>
            <ScrollView>
                <ListItemInput
                    value={text} onChangeText={text => setText(text)} placeholder={'请输入口令解锁功能'} autoFocus/>
            </ScrollView>
            {loading && <Loading/>}
        </Container>
    )
};

const passwordMaps: { [key: string]: string } = {
    'lx20230522': 'navBirthday2023',
    'CJ20230618BK': 'unlockPlugin',
}

export default FeatureUnlockScreen;